<template>
    <div>
        <div style="display: flex;align-content: center;">
        <h4 style="text-align: center;color: #235eff;width: 90%;">缺陷单</h4>
            <el-link v-if="dataForm.id" @click="downloadFile">下载</el-link>
        </div>
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm"  label-position="top">
            <el-row :gutter="10">
                <el-col :span="6">
                    <el-form-item label="缺陷设备" prop="equipmentId">
                        <el-select v-model="dataForm.equipmentId" @change="selectEquipment" clearable placeholder="请选择">
                            <el-option
                                v-for="item in equipmentList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="缺陷来源" prop="flawSource">
                        <el-select v-model="dataForm.flawSource" clearable placeholder="请选择">
                            <el-option
                                v-for="item in flawSourceList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="调管单位" prop="dispatchUnit">
                        <el-select v-model="dataForm.dispatchUnit" clearable placeholder="请选择">
                            <el-option
                                v-for="item in dispatchUnitList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="设备类别" prop="equipmentTypeName">
                        <el-input v-model="dataForm.equipmentTypeName" placeholder="设备类别" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="缺陷等级" prop="flawLevel">
                        <el-select v-model="dataForm.flawLevel" clearable placeholder="请选择">
                            <el-option
                                v-for="item in flawLevelList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="基建工程保修期" prop="projectWarrantyPeriod">
                        <el-date-picker
                            v-model="dataForm.projectWarrantyPeriod"
                            placeholder="请选择日期"
                            style="width: 100%;"
                            type="date"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="生产厂家" prop="manufacturer">
                        <el-input v-model="dataForm.manufacturer" placeholder="生产厂家" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="发现人" prop="discoverUserId">
                        <el-select v-model="dataForm.discoverUserId" @change="selectUser" filterable clearable placeholder="请选择">
                            <el-option
                                v-for="item in userList"
                                :key="item.userId"
                                :label="item.realName"
                                :value="item.userId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="投运日期" prop="commissionDate">
                        <el-date-picker
                            v-model="dataForm.commissionDate"
                            placeholder="请选择日期"
                            style="width: 100%;"
                            type="date"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="电压等级" prop="voltage">
                        <el-select v-model="dataForm.voltage" clearable placeholder="请选择">
                            <el-option
                                v-for="item in voltageList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="发现时间" prop="discoverDate">
                        <el-date-picker
                            v-model="dataForm.discoverDate"
                            placeholder="请选择日期"
                            style="width: 100%;"
                            type="date"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="应完成缺陷处理时间" prop="finishDate">
                        <el-date-picker
                            v-model="dataForm.finishDate"
                            placeholder="请选择日期"
                            style="width: 100%;"
                            type="date"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="缺陷描述" prop="flawDetail">
                <el-input type="textarea" :rows="5" v-model="dataForm.flawDetail" placeholder="请输入缺陷描述"></el-input>
            </el-form-item>
            <el-row :gutter="10">
                <el-col :span="12">
                    <el-form-item label="设备地点" prop="equipmentAddr">
                        <el-input v-model="dataForm.equipmentAddr" placeholder="设备地点"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="备注" prop="note">
                        <el-input v-model="dataForm.note" placeholder="备注"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item label="消缺前照片" prop="imgs">
                <image-uploader ref="ref_imgs"></image-uploader>
            </el-form-item>
        </el-form>
        <div class="submit-btn-group">
            <el-button @click="closeForm">关闭</el-button>
            <el-button @click="saveForm(0)" type="primary">保存</el-button>
            <el-button @click="submitForm" type="primary">保存并发布</el-button>
        </div>
    </div>
</template>

<script>
import ImageUploader from './components/image-uploader';
import {EQUIPMENT_MAIN_CATEGORY_NAME} from '@/utils/constants';
export default {
    components: {
        ImageUploader
    },
    data () {
        return {
            equipmentList: [],
            flawSourceList: [],
            dispatchUnitList: [],
            flawLevelList: [],
            voltageList: [],
            userList: [],
            fileList: [],
            dataForm: {
                id: 0,
                equipmentId: '',
                equipmentName: '',
                equipmentType: '',
                equipmentTypeName: '',
                manufacturer: '',
                voltage: '',
                flawSource: '',
                flawLevel: '',
                discoverUser: '',
                discoverUserId: '',
                discoverDate: '',
                dispatchUnit: '',
                projectWarrantyPeriod: '',
                commissionDate: '',
                finishDate: '',
                flawDetail: '',
                equipmentAddr: '',
                note: '',
                imgs: []
            },
            dataRule: {
                equipmentId: [
                    {required: true, message: '缺陷设备不能为空', trigger: 'blur'}
                ],
                voltage: [
                    {required: true, message: '电压等级不能为空', trigger: 'blur'}
                ],
                flawSource: [
                    {required: true, message: '缺陷来源不能为空', trigger: 'blur'}
                ],
                flawLevel: [
                    {required: true, message: '缺陷等级不能为空', trigger: 'blur'}
                ],
                discoverUser: [
                    {required: true, message: '发现人不能为空', trigger: 'blur'}
                ],
                discoverUserId: [
                    {required: true, message: '发现人ID不能为空', trigger: 'blur'}
                ],
                discoverDate: [
                    {required: true, message: '发现日期不能为空', trigger: 'blur'}
                ],
                dispatchUnit: [
                    {required: true, message: '调管单位不能为空', trigger: 'blur'}
                ],
                flawDetail: [
                    {required: true, message: '缺陷描述不能为空', trigger: 'blur'}
                ],
                equipmentAddr: [
                    {required: true, message: '设备地点不能为空', trigger: 'blur'}
                ],
                imgs: [
                    {required: true, message: '请上传消缺前照片', trigger: 'blur'}
                ]
            }
        };
    },
    created() {
        this.loadInitData();
    },
    methods: {

        loadInitData () {
            const dictMap = [
                {listName: 'flawSourceList', parentCode: 'flaw_source'},
                {listName: 'dispatchUnitList', parentCode: 'dispatch_unit'},
                {listName: 'flawLevelList', parentCode: 'flaw_level'},
                {listName: 'voltageList', parentCode: 'voltage'},
            ];
            this.$client.listDictionaryChildrenArr(dictMap.map((it) => it.parentCode)).then((res) => {
                let {data: resData} = res;
                for (let key in resData) {
                    let data = resData[key];
                    let dict = dictMap.filter((it) => it.parentCode === key)[0];
                    this[dict.listName] = data;
                }
            });
            // this.$client.listDictionaryChildren('flaw_source').then((res) => {
            //     this.flawSourceList = res.data;
            // });
            // this.$client.listDictionaryChildren('dispatch_unit').then((res) => {
            //     this.dispatchUnitList = res.data;
            // });
            // this.$client.listDictionaryChildren('flaw_level').then((res) => {
            //     this.flawLevelList = res.data;
            // });
            // this.$client.listDictionaryChildren('voltage').then((res) => {
            //     this.voltageList = res.data;
            // });
            this.$client.getAllUserList().then((res) => {
                this.userList = res.data;
            });
            this.$client.getFlawEquipmentList({mainCategory: ''}).then((res) => {
                this.equipmentList = res.data;
            });
        },
        init (row) {
            this.dataForm.id = row.businessFormId || 0;
            this.$nextTick(() => {
                this.$refs.dataForm.resetFields();
                if (this.dataForm.id) {
                    this.$client.getEquipmentFlawInfo(this.dataForm.id).then((res) => {
                        this.dataForm = res.data;
                        this.$refs.ref_imgs.init(this.dataForm.imgs);
                    });
                }
            });
        },
        downloadFile() {
            location.href = this.axios.defaults.baseURL + '/business/equipmentFlaws/download/' + this.dataForm.id + '?token=' + this.$store.state.token;
        },
        selectEquipment(val) {
            for (let item of this.equipmentList) {
                if (item.id === val) {
                    this.dataForm.equipmentName = item.name;
                    this.dataForm.equipmentType = item.mainCategory;
                    this.dataForm.equipmentTypeName = EQUIPMENT_MAIN_CATEGORY_NAME[item.mainCategory];
                    this.dataForm.commissionDate = item.commissionDate;
                    this.dataForm.projectWarrantyPeriod = item.qualityAssuranceDate;
                    this.dataForm.manufacturer = item.manufacturer;
                }
            }
        },
        selectUser(val) {
            for (let item of this.userList) {
                if (item.userId === val) {
                    this.dataForm.discoverUser = item.realName;
                }
            }
        },
        closeForm(type) {
            this.$emit('closeForm', type);
        },
        saveForm(formState) {
            this.fileList = this.$refs.ref_imgs.getFileList();
            if (this.fileList.length === 0) {
                this.$message({
                    message: '请上传消缺前图片！',
                    type: 'error',
                    duration: 1500
                });
                return;
            }
            this.dataForm.imgs = this.fileList;
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    this.dataForm.formState = formState ? formState : 0;
                    this.$client.saveOrSubmitEquipmentFlaw(this.dataForm).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        });
                        this.closeForm(1);
                    });
                }
            });
        },
        submitForm() {
            this.$confirm('确定发布吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.saveForm(1);
            });
        }
    }
};
</script>

