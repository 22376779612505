<template>
    <div>
        <!--信息展示组件-->
        <flow-drawing-apply-info v-if="showFlowDrawingApplyInfo" ref="ref_flowDrawingApplyInfo"></flow-drawing-apply-info>
        <flow-overhaul-detail v-if="showFlowOverhaulDetail" ref="ref_flowOverhaul_detail"></flow-overhaul-detail>
        <flaw-task-completion-info v-if="showFlawTaskCompletionInfo" ref="ref_FlawTaskCompletionInfo" @closeForm="closeForm"></flaw-task-completion-info>
        <flow-put-into-production-detail v-if="showPutIntoProductionDetail" ref="ref_flowPutIntoProduction_detail" @closeForm="closeForm"></flow-put-into-production-detail>
        <!--end-->
        <business-apply-show v-if="showBusinessApplyShow" :id="currentData.businessFormId" :showBack="false" @clickStep="clickStep"></business-apply-show>
        <reconnaissance-show v-if="showReconnaissanceShow" :id="currentData.businessFormId" :showBack="false" @clickStep="clickStep"></reconnaissance-show>
        <electric-plan-show v-if="showElectricPlanShow" :id="currentData.businessFormId" :showBack="false" @clickStep="clickStep"></electric-plan-show>
        <completion-apply-show v-if="showCompletionApplyShow" :id="currentData.businessFormId" :showBack="false" @clickStep="clickStep"></completion-apply-show>
        <completion-acceptance-show v-if="showCompletionAcceptanceShow" :id="currentData.businessFormId" :showBack="false" @clickStep="clickStep"></completion-acceptance-show>
        <div class="submit-btn-group">
            <el-button @click="closeForm">关闭</el-button>
        </div>
    </div>
</template>

<script>
/**
 * 通用信息展示组件
 */
import FlowDrawingApplyInfo from '@/pages/drawing/drawingApply-detail';
import FlowOverhaulDetail from '@/pages/overhaul/overhaulDetail';
import BusinessApplyShow from '../businessApply/businessApplyShow';
import ReconnaissanceShow from '../reconnaissance/reconnaissanceShow';
import ElectricPlanShow from '../electricPlan/electricPlanShow';
import CompletionApplyShow from '../completionApply/completionApplyShow';
import CompletionAcceptanceShow from '../completionAcceptance/completionAcceptanceShow';
import FlawTaskCompletionInfo from '@/pages/emergencyRepair/flawTaskCompletionInfo';
import FlowPutIntoProductionDetail from '@/pages/putIntoProduction/putIntoProductionDetail';
export default {
    components: {
        FlowDrawingApplyInfo,
        FlowOverhaulDetail,
        BusinessApplyShow,
        ReconnaissanceShow,
        ElectricPlanShow,
        CompletionApplyShow,
        CompletionAcceptanceShow,
        FlawTaskCompletionInfo,
        FlowPutIntoProductionDetail,
    },
    data() {
        return {
            currentData: {},
            showFlowDrawingApplyInfo: false,
            showFlowOverhaulDetail: false,
            showBusinessApplyShow: false,
            showReconnaissanceShow: false,
            showElectricPlanShow: false,
            showCompletionApplyShow: false,
            showCompletionAcceptanceShow: false,
            showFlawTaskCompletionInfo: false,
            showPutIntoProductionDetail: false,
            data: {}
        };
    },
    methods: {
        init(item) {
            this.currentData = item;
            switch (item.processDefinitionKey) {
                case 'flow_business_apply':
                    // eslint-disable-next-line default-case
                    switch (item.businessFormData.phaseStatus) {
                        case '0':
                            this.showBusinessApplyShow = true;
                            break;
                        case '10':
                            this.showReconnaissanceShow = true;
                            break;
                        case '20':
                            this.showElectricPlanShow = true;
                            break;
                        case '30':
                            this.showCompletionAcceptanceShow = true;
                            break;
                        default:
                            this.showBusinessApplyShow = true;
                    }
                    break;
                case 'flow_drawing_apply':
                    this.showFlowDrawingApplyInfo = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flowDrawingApplyInfo.init(item.businessFormId);
                    });
                    break;
                case 'flow_overhaul':
                case 'flow_overhaul_1':
                    this.showFlowOverhaulDetail = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flowOverhaul_detail.init(item);
                    });
                    break;
                case 'flow_emergency_repair':
                    this.showFlawTaskCompletionInfo = true;
                    this.$nextTick(() => {
                        this.$refs.ref_FlawTaskCompletionInfo.init(item, item.id);
                    });
                    break;
                case 'flow_put_into_production':
                    this.showPutIntoProductionDetail = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flowPutIntoProduction_detail.init(item, item.id);
                    });
                    break;
                default:
                    break;
            }
        },
        closeForm(type) {
            this.$emit('closeForm', type);
        },
        clickStep(index) {
            //this.$emit('clickStep', index);

            let status = this.currentData.businessFormData.phaseStatus;
            switch (index) {
                case 0:
                    this.showBusinessApplyShow = true;
                    this.showReconnaissanceShow = false;
                    this.showElectricPlanShow = false;
                    this.showCompletionApplyShow = false;
                    this.showCompletionAcceptanceShow = false;
                    break;
                case 1:
                    if (status >= '10') {
                        this.showReconnaissanceShow = true;
                        this.showBusinessApplyShow = false;
                        this.showElectricPlanShow = false;
                        this.showCompletionApplyShow = false;
                        this.showCompletionAcceptanceShow = false;
                    }
                    break;
                case 2:
                    if (status >= '20') {
                        this.showElectricPlanShow = true;
                        this.showBusinessApplyShow = false;
                        this.showReconnaissanceShow = false;
                        this.showCompletionApplyShow = false;
                        this.showCompletionAcceptanceShow = false;
                    }
                    break;
                case 3:
                    if (status >= '30') {
                        this.showCompletionAcceptanceShow = true;
                        this.showBusinessApplyShow = false;
                        this.showReconnaissanceShow = false;
                        this.showElectricPlanShow = false;
                        this.showCompletionApplyShow = false;
                    }
                    break;
                default:
                    break;
            }
        }
    }
};
</script>
