<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item prop="designScopes" label="设计范围(两项均未勾选,项目整体免设计)">
                        <el-checkbox-group v-model="formData.designScopes">
                            <el-checkbox
                                v-for="item in keyValues.designScopes"
                                :label="parseInt(item.itemKey)"
                                :key="parseInt(item.itemKey)"
                                >{{ item.itemValue }}</el-checkbox
                            >
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="designPicNo" label="业扩典设参考图号">
                        <el-input v-model="formData.designPicNo"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'DesignInfo',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    designScopes: [],
                    designPicNo: ''
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
            },
            keyValues: {
                designScopes: [],
            }
        };
    },
    methods: {
        async bindKeyValues() {
            const data = await Promise.all([
                this.$client.getDicData('design_scope'),
            ]);
            this.keyValues.designScopes = data[0].data || [];
        },
    },
    created() {
        this.bindKeyValues();
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>


