<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item prop="isHotLineWork" label="是否带电作业">
                         <el-radio v-model="formData.isHotLineWork" v-for="i in keyValues.isHotLineWork" :label="i.itemKey" :key="i.itemKey">{{i.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item v-if="formData.isHotLineWork===1" prop="plannedDate" label="计划用电时间">
                        <el-date-picker
						v-model="formData.plannedDate"
						type="date"
						placeholder="选择日期"
						value-format="yyyy-MM-dd">
						</el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'HotLineWorkInfo',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    isHotLineWork: '',
                    plannedDate: ''
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                isHotLineWork: [
                    {
                        required: true,
                        message: '是否带电作业 ' + '必填',
                        trigger: 'change'
                    }
                ],
                plannedDate: [
                    {
                        required: true,
                        message: '计划用电时间 ' + '必填',
                        trigger: 'change'
                    }
                ]
            },
            keyValues: {
                isHotLineWork: [
                    {itemKey: 1, itemValue: '是'},
                    {itemKey: 0, itemValue: '否'},
                ],
            }
        };
    },
    methods: {
        async bindKeyValues() {
            // const data = await Promise.all([
            //     this.$client.getDicData('metering_type'),
            // ]);
            // this.keyValues.meteringType = data[0].data || [];
        },
    },
    created() {
        this.bindKeyValues();
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>


