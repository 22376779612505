<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item prop="assetTransferScope" label="设备是否属于资产移交范围">
                        <el-radio v-model="formData.assetTransferScope" v-for="i in keyValues.assetTransferScope" :label="i.itemKey" :key="i.itemKey">{{i.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="isAssetTransfer" label="设备是否愿意移交">
                        <el-radio v-model="formData.isAssetTransfer" v-for="i in keyValues.isAssetTransfer" :label="i.itemKey" :key="i.itemKey">{{i.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'AssetTransferInfo',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    assetTransferScope: '',
                    isAssetTransfer: ''
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                assetTransferScope: [
                    {
                        required: true,
                        message: '设备是否属于资产移交范围 ' + '必填',
                        trigger: 'change'
                    }
                ],
                isAssetTransfer: [
                    {
                        required: true,
                        message: '设备是否愿意移交 ' + '必填',
                        trigger: 'change'
                    }
                ]
            },
            keyValues: {
                assetTransferScope: [
                    {itemKey: 1, itemValue: '是'},
                    {itemKey: 0, itemValue: '否'},
                ],
                isAssetTransfer: [
                    {itemKey: 1, itemValue: '是(需签订协议)'},
                    {itemKey: 0, itemValue: '否'},
                ]
            }
        };
    },
    methods: {
        async bindKeyValues() {
            // const data = await Promise.all([
            //     this.$client.getDicData('metering_type'),
            // ]);
            // this.keyValues.meteringType = data[0].data || [];
        },
    },
    created() {
        this.bindKeyValues();
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>


