<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item prop="businessName" :label="'计量点'+(index+1)+'执行电价'">
                        <el-radio v-model="formData.businessName" v-for="i in keyValues.businessName" :label="i.itemKey" :key="i.itemKey">{{i.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item prop="businessSupplyVoltage" label="电压(kVA)">
                        <el-input v-model="formData.businessSupplyVoltage"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item v-if="formData.businessName==='大工业'" prop="chargeCollectType" label="基本电费收取方式">
                        <el-radio v-model="formData.chargeCollectType" v-for="i in keyValues.chargeCollectType" :label="parseInt(i.itemKey)" :key="i.itemKey">{{i.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item prop="powerFactorStandard" label="功率因素执行标准">
                        <el-radio v-model="formData.powerFactorStandard" v-for="i in keyValues.powerFactorStandard" :label="i.itemKey" :key="i.itemKey">{{i.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="isTimeDivision" label="是否分时">
                        <el-radio v-model="formData.isTimeDivision" v-for="i in keyValues.isTimeDivision" :label="parseInt(i.itemKey)" :key="i.itemKey">{{i.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'ElecChargeInfo',
    components: {
    },
    props: {
        index: {
            default: 0
        },
        formData: {
            type: Object,
            default: () => {
                return {
                    id: '',
                    businessName: '',
                    businessSupplyVoltage: '',
                    chargeCollectType: '',
                    powerFactorStandard: '',
                    isTimeDivision: ''
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                businessName: [
                    {
                        required: true,
                        message: '执行电价 ' + '必填',
                        trigger: 'change'
                    }
                ],
                businessSupplyVoltage: [
                    {
                        required: true,
                        message: '电压 ' + '必填',
                        trigger: 'change'
                    }
                ],
                chargeCollectType: [
                    {
                        required: true,
                        message: '基本电费收取方式 ' + '必填',
                        trigger: 'change'
                    }
                ],
                powerFactorStandard: [
                    {
                        required: true,
                        message: '功率因素执行标准 ' + '必填',
                        trigger: 'change'
                    }
                ],
                isTimeDivision: [
                    {
                        required: true,
                        message: '是否分时 ' + '必填',
                        trigger: 'change'
                    }
                ],
            },
            keyValues: {
                businessName: [
                    {itemKey: '大工业', itemValue: '大工业'},
                    {itemKey: '居民生活', itemValue: '居民生活'},
                    {itemKey: '农业生产', itemValue: '农业生产'},
                    {itemKey: '一般工商业及其他(非居民照明)', itemValue: '一般工商业及其他(非居民照明)'},
                    {itemKey: '一般工商业及其他(非普)', itemValue: '一般工商业及其他(非普)'},
                    {itemKey: '一般工商业及其他(非工业)', itemValue: '一般工商业及其他(非工业)'},
                    {itemKey: '一般工商业及其他(商业)', itemValue: '一般工商业及其他(商业)'},
                ],
                chargeCollectType: [],
                powerFactorStandard: [
                    {itemKey: 0, itemValue: '不执行'},
                    {itemKey: 0.8, itemValue: '0.8'},
                    {itemKey: 0.85, itemValue: '0.85'},
                    {itemKey: 0.9, itemValue: '0.9'},
                ],
                isTimeDivision: [
                    {itemKey: 0, itemValue: '是'},
                    {itemKey: 1, itemValue: '否'},
                ]
            }
        };
    },
    methods: {
        async bindKeyValues() {
            const data = await Promise.all([
                this.$client.getDicData('charge_collect_type'),
            ]);
            this.keyValues.chargeCollectType = data[0].data || [];
        },
    },
    created() {
        this.bindKeyValues();
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
.el-radio{
    margin-right:15px;
}
</style>


