<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="$t('businessApply.form.equipmentName')" prop="equipmentName">
                        <el-input v-model="formData.equipmentName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="$t('businessApply.form.equipmentType')" prop="equipmentType">
                        <el-input v-model="formData.equipmentType"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="$t('businessApply.form.nominalVoltage')" prop="nominalVoltage">
                        <el-input v-model="formData.nominalVoltage"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="$t('businessApply.form.singleCapacity')" prop="singleCapacity">
                        <el-input-number :min="0" v-model="formData.singleCapacity" style="width: 100%;"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="$t('businessApply.form.num')" prop="num">
                        <el-input-number :min="1" v-model="formData.num" style="width: 100%;"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="$t('businessApply.form.capacityCount')" prop="capacityCount">
                        <el-input v-model="formData.capacityCount"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="$t('businessApply.form.note')" prop="note">
                        <el-input type="textarea" :rows="4" v-model="formData.note"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'EquipmentInfo',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    equipmentName: '',
                    equipmentType: '',
                    nominalVoltage: '',
                    singleCapacity: '',
                    num: '',
                    capacityCount: 0,
                    note: ''
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                equipmentName: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.equipmentName') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                /*equipmentType: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.equipmentType') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],*/
                nominalVoltage: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.nominalVoltage') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                singleCapacity: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.singleCapacity') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                num: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.num') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                capacityCount: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.capacityCount') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
            },
            keyValues: {
            }
        };
    },
    watch: {
        formData: {
            handler(newVal) {
                if (newVal.singleCapacity && newVal.num) {
                    newVal.capacityCount = Number(newVal.singleCapacity) * Number(newVal.num);
                    newVal.num = Number(newVal.num);
                } else {
                    newVal.capacityCount = 0;
                }
            },
            deep: true
        }
    },
    computed: {},
    methods: {
        async bindKeyValues() {

        },
        async initFormValue() {
            await this.bindKeyValues();
        },
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
</style>


