<template>
    <div>
        <flow-drawing-apply
            v-if="flowDrawingApplyVisible"
            ref="ref_flowDrawingApply"
            @closeForm="closeForm"
        ></flow-drawing-apply>
        <overhaul-apply
            v-if="overhaulApplyVisible"
            ref="ref_overhaulApply"
            @closeForm="closeForm"></overhaul-apply>
        <overhaul-receive
            v-if="overhaulReceiveVisible"
            ref="ref_overhaulReceive"
            @closeForm="closeForm"></overhaul-receive>
        <put-into-production-apply
            v-if="putIntoProductionApplyVisible"
            ref="ref_putIntoProductionApply"
            @closeForm="closeForm"></put-into-production-apply>
        <put-into-production-receive
            v-if="putIntoProductionReceiveVisible"
            ref="ref_putIntoProductionReceive"
            @closeForm="closeForm"></put-into-production-receive>
        <business-apply v-if="businessApplyVisible" ref="ref_businessApply" :id="currentData.businessFormId" @closePanel="closeForm" @clickStep="clickStep"></business-apply>
        <reconnaissance
            v-if="reconnaissanceVisible"
            ref="ref_reconnaissance"
            :id="currentData.businessFormId"
            :workflowTaskId="currentData.id"
            :taskStatus="currentData.taskStatus"
            @closePanel="closeForm"
             @clickStep="clickStep"
        ></reconnaissance>
        <electric-plan-form
        v-if="electricPlanVisible"
        ref="ref_electricPlan"
        :id="currentData.businessFormId"
        :workflowTaskId="currentData.id"
        :taskStatus="currentData.taskStatus"
        :workflowId="currentData.workflowId"
        businessFormKey="form_electric_plan"
        @closePanel="closeForm"
        @clickStep="clickStep"></electric-plan-form>
        <completion-apply
            v-if="completionApplyVisible"
            ref="ref_completionApply"
            @closePanel="closeForm"
            @clickStep="clickStep"
        ></completion-apply>
        <completion-acceptance
            v-if="completionAcceptanceVisible"
            ref="ref_completionAcceptance"
            @closeForm="closeForm"
            @clickStep="clickStep"
        ></completion-acceptance>
        <datum-archive v-if="datumArchiveVisible" ref="ref_datumArchive" @closeForm="closeForm"></datum-archive>
        <equipment-flaw v-if="emergencyRepairVisible" ref="ref_emergencyRepair" @closeForm="closeForm"></equipment-flaw>
        <flaw-task v-if="flawTaskVisible" ref="ref_flawTask" @closeForm="closeForm"></flaw-task>
        <flaw-task-accept v-if="flawTaskAcceptVisible" ref="ref_flawTaskAccept" @closeForm="closeForm"></flaw-task-accept>
        <flaw-task-completion v-if="flawTaskCompletionVisible" ref="ref_flawTaskCompletion" @closeForm="closeForm"></flaw-task-completion>
    </div>
</template>

<script>
import FlowDrawingApply from '../drawing/create';
import BusinessApply from '../businessApply/businessApplyForm';
import Reconnaissance from '../reconnaissance/reconnaissanceForm';
import ElectricPlanForm from '../electricPlan/electricPlanForm';
import CompletionApply from '../completionApply/completionApply';
import CompletionAcceptance from '../completionAcceptance/completionAcceptance';
import DatumArchive from '../datumArchive/datumArchive';
import OverhaulApply from '../overhaul/overhaulCreate';
import OverhaulReceive from '../overhaul/overhaulReceive';
import EquipmentFlaw from '../emergencyRepair/equipmentFlawForm';
import FlawTask from '../emergencyRepair/flawTaskForm';
import FlawTaskAccept from '../emergencyRepair/flawTaskAcceptForm';
import FlawTaskCompletion from '../emergencyRepair/flawTaskCompletionForm';
import PutIntoProductionApply from '../putIntoProduction/putIntoProductionCreate';
import PutIntoProductionReceive from '../putIntoProduction/putIntoProductionReceive';

export default {
    components: {
        BusinessApply,
        FlowDrawingApply,
        ElectricPlanForm,
        Reconnaissance,
        CompletionAcceptance,
        CompletionApply,
        DatumArchive,
        OverhaulApply,
        OverhaulReceive,
        EquipmentFlaw,
        FlawTask,
        FlawTaskAccept,
        FlawTaskCompletion,
        PutIntoProductionApply,
        PutIntoProductionReceive,
    },
    data() {
        return {
            currentData: {},
            businessApplyVisible: false,
            flowDrawingApplyVisible: false,
            reconnaissanceVisible: false,
            electricPlanVisible: false,
            completionApplyVisible: false,
            completionAcceptanceVisible: false,
            datumArchiveVisible: false,
            overhaulApplyVisible: false,
            overhaulReceiveVisible: false,
            emergencyRepairVisible: false,
            flawTaskVisible: false,
            flawTaskAcceptVisible: false,
            flawTaskCompletionVisible: false,
            putIntoProductionApplyVisible: false,
            putIntoProductionReceiveVisible: false,
        };
    },
    methods: {
        create(item) {
            switch (item.processDefinitionKey) {
                case 'flow_business_apply':
                    this.businessApplyVisible = true;
                    //   this.$nextTick(() => {
                    //       this.$refs.ref_businessApply;
                    //   });
                    break;
                case 'flow_drawing_apply':
                    this.flowDrawingApplyVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flowDrawingApply.init(item);
                    });
                    break;
                case 'flow_overhaul':
                    this.overhaulApplyVisible = true;
                    item.type = 1;
                    this.$nextTick(() => {
                        this.$refs.ref_overhaulApply.init(item);
                    });
                    break;
                case 'flow_overhaul_1':
                    this.overhaulApplyVisible = true;
                    item.type = 2;
                    this.$nextTick(() => {
                        this.$refs.ref_overhaulApply.init(item);
                    });
                    break;
                case 'flow_emergency_repair':
                    this.emergencyRepairVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_emergencyRepair.init(item);
                    });
                    break;
                case 'flow_put_into_production':
                    this.putIntoProductionApplyVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_putIntoProductionApply.init(item);
                    });
                    break;
                default:
                    break;
            }
        },
        edit(item) {
            this.currentData = item;
            switch (item.processDefinitionKey) {
                case 'flow_business_apply':
                    this.businessApplyVisible = true;
                    this.$nextTick(() => {
                        // this.$refs.ref_businessApply.init(item);
                    });
                    break;
                case 'flow_drawing_apply':
                    this.flowDrawingApplyVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flowDrawingApply.init(item);
                    });
                    break;
                case 'flow_overhaul':
                case 'flow_overhaul_1':
                    this.overhaulApplyVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_overhaulApply.init(item);
                    });
                    break;
                case 'flow_emergency_repair':
                    this.emergencyRepairVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_emergencyRepair.init(item);
                    });
                    break;
                case 'flow_put_into_production':
                    this.putIntoProductionApplyVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_putIntoProductionApply.init(item);
                    });
                    break;
                default:
                    break;
            }
        },
        handle(item) {
            switch (item.processDefinitionKey) {
                case 'flow_business_apply':
                    this.handelBusinessApply(item);
                    break;
                case 'flow_drawing_apply':
                    this.flowDrawingApplyVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flowDrawingApply.init();
                    });
                    break;
                case 'flow_overhaul':
                case 'flow_overhaul_1':
                    this.overhaulReceiveVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_overhaulReceive.init(item);
                    });
                    break;
                case 'flow_emergency_repair':
                    this.handelEmergencyRepair(item);
                    break;
                case 'flow_put_into_production':
                    this.putIntoProductionReceiveVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_putIntoProductionReceive.init(item);
                    });
                    break;
                default:
                    break;
            }
        },
        handelEmergencyRepair(item) {
            switch (item.formKey) {
                case 'form_flaw_task':
                    this.flawTaskVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flawTask.init(item);
                    });
                    break;
                case 'form_flaw_task_accept':
                    this.flawTaskAcceptVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flawTaskAccept.init(item);
                    });
                    break;
                case 'form_flaw_task_completion':
                    this.flawTaskCompletionVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flawTaskCompletion.init(item);
                    });
                    break;
                default:
                    break;
            }
        },
        handelBusinessApply(item) {
            this.currentData = item;
            switch (item.formKey) {
                case 'flow_business_apply':
                    this.businessApplyVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_businessApply.init(item);
                    });
                    break;
                case 'form_reconnaissance':
                    this.reconnaissanceVisible = true;
                    //   this.$nextTick(() => {
                    //       this.$refs.ref_reconnaissance.init(item);
                    //   });
                    break;
                case 'form_electric_plan':
                    this.electricPlanVisible = true;
                    //   this.$nextTick(() => {
                    //       this.$refs.ref_electricPlan.init(item);
                    //   });
                    break;
                case 'form_completion_apply':
                    this.completionApplyVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_completionApply.init(item);
                    });
                    break;
                case 'form_completion_acceptance':
                    this.completionAcceptanceVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_completionAcceptance.init(item);
                    });
                    break;
                case 'form_datum_archive':
                    this.datumArchiveVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_datumArchive.init(item);
                    });
                    break;
                default:
                    break;
            }
        },
        info(item) {},
        closeForm(type) {
            this.$emit('closeForm', type);
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        }
    }
};
</script>
