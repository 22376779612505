<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item prop="isInvestmentInterface" label="是否实施业扩投资界面延伸">
                        <el-radio v-model="formData.isInvestmentInterface" v-for="i in keyValues.isInvestmentInterface" :label="i.itemKey" :key="i.itemKey">{{i.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'InvestmentInterfaceInfo',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    isInvestmentInterface: ''
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                isInvestmentInterface: [
                    {
                        required: true,
                        message: '是否实施业扩投资界面延伸 ' + '必填',
                        trigger: 'change'
                    }
                ]
            },
            keyValues: {
                isInvestmentInterface: [
                    {itemKey: 1, itemValue: '是(需签订协议)'},
                    {itemKey: 0, itemValue: '否(需客户申明书)'},
                ],
            }
        };
    },
    methods: {
        async bindKeyValues() {
            // const data = await Promise.all([
            //     this.$client.getDicData('metering_type'),
            // ]);
            // this.keyValues.isInvestmentInterface = data[0].data || [];
        },
    },
    created() {
        this.bindKeyValues();
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>


