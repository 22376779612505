<template>
    <div class="ui-layout-container ui-width-100 workflow-list__wrap">
        <div class="workflow-list" v-if="flowPanel">
            <div class="workflow-list__header">
                <div class="workflow-list__navbar">
                    <el-menu :default-active="type" class="el-menu-bm" mode="horizontal" @select="handleSelect">
                        <el-menu-item index="getAllWorkflow">所有业务</el-menu-item>
                        <el-menu-item index="getMyWorkflow">我发起的</el-menu-item>
                        <el-menu-item index="getMyHandleTask">待我处理
                            <!-- <span class="workflow-list__notice-count">10</span> -->
                        </el-menu-item>
                        <el-menu-item index="getMyFinishTask">已处理的</el-menu-item>
                    </el-menu>
                </div>
                <el-row :gutter="24" class="workflow-list__nav-form" style="margin: 6px 0px 0px 0px">
                    <el-col :span="4">
                        <div class="">
                            <el-select @change="state" v-model="dataForm.processDefinitionKey" :clearable="true" placeholder="请选择类型">
                                <el-option
                                    v-for="item in flowList"
                                    :key="item.processDefinitionKey"
                                    :label="item.processDefinitionName"
                                    :value="item.processDefinitionKey"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="">
                            <el-select v-model="dataForm.currentStatus" :clearable="true" placeholder="请选择状态">
                                <el-option
                                    v-for="item in statusList"
                                    :key="item.itemKey"
                                    :label="item.itemValue"
                                    :value="item.itemKey"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="">
                            <el-input v-model="dataForm.key" placeholder="请输入工作单号" clearable></el-input>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div>
                            <el-button @click="search" type="primary" icon="el-icon-search" style="border-radius: 13px">搜索</el-button>
                            <el-button @click="refresh" type="primary" icon="el-icon-refresh" style="border-radius: 13px">重置</el-button>
                            <!--<el-dropdown @command="handleCommand" icon="el-icon-refresh">
                                <el-button type="primary" icon="el-icon-plus" style="margin-left:10px;">
                                    新建<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <template v-for="item in flows.keys()">
                                        <el-dropdown-item
                                            v-for="(itembtn, index) in flows.get(item)"
                                            type="primary"
                                            plain
                                            :key="index"
                                            :command="itembtn"
                                            >{{ itembtn.processDefinitionName }}</el-dropdown-item
                                        >
                                    </template>
                                </el-dropdown-menu>
                            </el-dropdown>-->
                        </div>
                    </el-col>
                    <el-col :span="8" style="text-align: right">
                        <el-dropdown @command="handleCommand" icon="el-icon-refresh">
                            <el-button type="primary" icon="el-icon-plus" style="margin-left:10px;">
                                新建<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <template v-for="item in flows.keys()">
                                    <el-dropdown-item
                                        v-for="(itembtn, index) in flows.get(item)"
                                        type="primary"
                                        plain
                                        :key="index"
                                        :command="itembtn"
                                    >{{ itembtn.processDefinitionName }}</el-dropdown-item
                                    >
                                </template>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                </el-row>
            </div>
            <div class="workflow-list__content">
                <!-- 自定义卡片示例1 - 业务管理 -->
                <div v-if="dataList.length">
                    <template v-for="item in dataList">
                        <el-card :key="item.id" class="workflow-list__item-card">
                            <div slot="header" class="workflow-list__card-head-border">
                                <span
                                >工作单号: {{ item.businessNumber }}
                                    <span class="workflow-list__card-head-type">{{
                                        item.processDefinitionName
                                    }}</span></span
                                >
                                <span style="float: right">{{ item.currentStatusName }}</span>
                            </div>
                            <el-form :inline="true" class="my-form">
                                <!--不同流程会有不同的信息展示-->
                                <div v-if="item.processDefinitionKey == 'flow_business_apply'">
                                    <el-form-item label="客户名称：">
                                        {{
                                        item.businessFormData.customerEntity
                                        ? item.businessFormData.customerEntity.customerName
                                        : ''
                                        }}
                                    </el-form-item>
                                    <el-form-item label="客户编号：">
                                        {{
                                        item.businessFormData.customerEntity
                                        ? item.businessFormData.customerEntity.customerNumber
                                        : ''
                                        }}
                                    </el-form-item>
                                    <el-form-item label="用电地址：">
                                        {{
                                        item.businessFormData.customerEntity
                                        ? item.businessFormData.customerEntity.useAddr
                                        : ''
                                        }}
                                    </el-form-item>
                                    <el-form-item label="供电电压："> {{ item.businessFormData.voltage }}KV </el-form-item>
                                    <el-form-item label="供电电容：">
                                        {{ item.businessFormData.totalCapacity }}KW
                                    </el-form-item>
                                </div>
                                <div v-else-if="item.processDefinitionKey == 'flow_drawing_apply'">
                                    <el-form-item label="图片名称：">
                                        {{ item.businessFormData.drawingName }}
                                    </el-form-item>
                                    <el-form-item label="图纸数量：">
                                        {{ item.businessFormData.drawingNum }}张
                                    </el-form-item>
                                </div>
                                <div
                                    v-else-if="
                                        item.processDefinitionKey == 'flow_overhaul' ||
                                        item.processDefinitionKey == 'flow_overhaul_1'
                                    "
                                >
                                    <el-form-item label="工作负责人：">
                                        {{ item.businessFormData.chargeName }}
                                    </el-form-item>
                                    <el-form-item label="工作负责人电话：">
                                        {{ item.businessFormData.chargeTel }}
                                    </el-form-item>
                                </div>
                                <div
                                    v-else-if="item.processDefinitionKey == 'flow_put_into_production'"
                                >
                                    <el-form-item label="工作负责人：">
                                        {{ item.businessFormData.chargeName }}
                                    </el-form-item>
                                    <el-form-item label="工作负责人电话：">
                                        {{ item.businessFormData.chargeTel }}
                                    </el-form-item>
                                </div>
                                <div v-else-if="item.processDefinitionKey == 'flow_emergency_repair'">
                                    <el-form-item label="缺陷设备：">
                                        {{ item.businessFormData.equipmentName }}
                                    </el-form-item>
                                    <el-form-item label="应完成缺陷处理时间：">
                                        {{ item.businessFormData.finishDate }}
                                    </el-form-item>
                                </div>
                                <!--end-->
                                <el-row style="color: #999999">
                                    <el-col :span="4">
                                        发起人：{{item.workflowCreateBy}}
                                    </el-col>
                                    <el-col :span="16">
                                        发起时间：{{item.workflowCreateTime}}
                                    </el-col>
                                    <el-col :span="4">
                                        <div
                                            v-if="type == 'getMyWorkflow' || type == 'getAllWorkflow'">
                                            <el-button
                                                type="info"
                                                size="small"
                                                @click="openInfo(item)"
                                                class="workflow-list__action-btn"
                                            >详情</el-button>
                                        </div>
                                        <div
                                            v-if="(type == 'getMyWorkflow' && item.status == '0') || item.currentStatus == 'D' || item.currentStatus == 'C'">
                                            <el-button
                                                type="info"
                                                size="small"
                                                @click="deleteFlow(item.id)"
                                                class="workflow-list__action-btn"
                                            >删除</el-button>
                                            <el-button
                                                type="info"
                                                size="small"
                                                @click="editFlow(item)"
                                                class="workflow-list__action-btn"
                                            >编辑</el-button>
                                        </div>
                                        <div v-else-if="type == 'getMyHandleTask'">
                                            <el-button
                                                type="info"
                                                size="small"
                                                @click="handelFlow(item)"
                                                class="workflow-list__action-btn"
                                            >处理
                                            </el-button>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </el-card>
                    </template>
                </div>
                <empty v-else></empty>
            </div>
            <div class="workflow-list__foot">
                <el-row :gutter="24">
                    <el-col :span="8" :offset="8">
                        <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page="pageData.current"
                            :page-size="pageData.size"
                            background
                            layout="total, prev, pager, next, jumper"
                            :total="total"
                        ></el-pagination>
                    </el-col>
                </el-row>
            </div>
            <!-- <review v-if="showReview" ref="review_win" @closeForm="closeForm"></review> -->
        </div>
        <div class="load-workflow-step" v-else>
            <template v-if="showStepInfo">
                <business-apply-show
                    v-if="showStepIndex === 0"
                    :id="currentRow.businessFormId"
                    @closePanel="closePanel"
                ></business-apply-show>
                <reconnaissance-show
                    v-if="showStepIndex === 1"
                    :id="currentRow.businessFormId"
                    @closePanel="closePanel"
                ></reconnaissance-show>
                <electric-plan-show
                    v-if="showStepIndex === 2"
                    :id="currentRow.businessFormId"
                    @closePanel="closePanel"
                ></electric-plan-show>
            </template>
            <div v-show="!showStepInfo">
                <review v-if="showReview" ref="review_win" @closeForm="closeForm" @clickStep="clickStep"></review>
                <handle v-if="showHandel" ref="handel_win" @closeForm="closeForm" @clickStep="clickStep"></handle>
                <info v-if="showInfo" ref="info_win" @closeForm="closeForm" @clickStep="clickStep"></info>
                <notice v-if="showNotice" ref="notice_win" @closeForm="closeForm" @clickStep="clickStep"></notice>
            </div>
        </div>
    </div>
</template>

<script>
import Handle from './handle';
import Review from './review';
import Info from './info';
import Notice from './notice';
import BusinessApplyShow from '../businessApply/businessApplyShow';
import ReconnaissanceShow from '../reconnaissance/reconnaissanceShow';
import ElectricPlanShow from '../electricPlan/electricPlanShow';
import PowerSupplyPlan from '../powerSupplyPlan/index';
import CompletionApply from '../completionApply/completionApply';
export default {
    name: 'WorkflowList',
    components: {
        Handle,
        Review,
        Info,
        Notice,
        BusinessApplyShow,
        ReconnaissanceShow,
        ElectricPlanShow,
    },
    data() {
        return {
            flowApplyID: 0,
            flowPanel: true,
            flowAction: 0,
            flowList: [],
            flows: new Map(),
            dataList: [],
            flowGroupMap: [],
            statusList: [],
            /*修改*/
            businessstatusList: [],
            equipmentstatusList: [],
            drawingstatusList: [],
            overhaulstatusList: [],
            overhaulstatusList1: [],
            putIntoProductionStatusList: [],
            type: 'getMyHandleTask',
            showCreateFlow: false,
            showHandel: false,
            showNotice: false,
            showInfo: false,
            total: 0,
            showReview: false,
            pageData: {
                current: 1,
                size: 5,
            },
            //processDefinitionKey1: ['flow_business_apply,flow_drawing_apply,flow_emergency_repair'],
            dataForm: {
                processDefinitionKey: '',
                key: '',
                currentStatus: '',
            },
            showStepInfo: false,
            showStepIndex: 0,
            currentRow: {},
        };
    },
    filters: {
        filterFlowGroup(val) {
            return this.flowGroupMap[val];
        },
    },
    methods: {
        init() {
            this.dataForm.currentStatus = '';
        },
        state(index) {
            this.init();
            switch (index) {
                case 'flow_business_apply':
                    this.statusList = this.businessstatusList;
                    break;
                case 'flow_drawing_apply':
                    this.statusList = this.drawingstatusList;
                    break;
                case 'flow_emergency_repair':
                    this.statusList = this.equipmentstatusList;
                    break;
                    /*case 'flow_overhaul':
                        this.statusList = this.overhaulstatusList;
                        break;
                    case 'form_overhaul_1':
                        this.statusList = this.overhaulstatusList1;
                        break;
                    case 'form_put_into_production':
                        this.statusList = this.putIntoProductionStatusList;
                        break;*/
                default:
                    this.statusList = [];
            }
        },
        createFlow(row) {
            this.showCreateFlow = false;
            this.showHandel = true;
            this.flowAction = 0;
            this.flowPanel = false;
            this.$nextTick(() => {
                this.$refs.handel_win.create(row);
            });
        },
        editFlow(item) {
            this.showHandel = true;
            this.flowPanel = false;
            this.$nextTick(() => {
                this.$refs.handel_win.edit(item);
            });
        },
        handleCurrentChange(val) {
            this.pageData.current = val;
            this.getData(this.type);
        },
        getData(methods) {
            let page = {
                ...this.pageData,
                ...this.dataForm,
            };
            this.$client[methods](page).then((res) => {
                this.dataList = res.data.records.map((e) => {
                    if (e.businessFormData == null) {
                        e.businessFormData = {};
                    }
                    return e;
                });
                this.total = res.data.total;
                this.loading = false;
            });
        },

        search() {
            this.getData(this.type);
        },
        getRoleFlow() {
            this.$client.getRoleFlow().then((res) => {
                let map = new Map();
                for (let item of res.list) {
                    let group = map.get(item.flowGroup) ? map.get(item.flowGroup) : [];
                    if (item.businessFormKey === 'form_business_apply') {
                        group.push(item);
                        this.flowList[0] = item;
                    } else if (item.businessFormKey === 'form_equipment_flaw') {
                        group.push(item);
                        this.flowList[1] = item;
                    } else if (item.businessFormKey === 'flow_drawing_apply') {
                        group.push(item);
                        this.flowList[2] = item;
                    }
                    map.set(item.flowGroup, group);
                }
                this.flows = map;
            });
        },
        /*this.dataForm.processDefinitionKey = '';*/
        refresh() {
            this.dataForm.processDefinitionKey = '';
            this.dataForm.key = '';
            this.dataForm.currentStatus = '';
            this.search();
        },
        handleCommand(v) {
            this.createFlow(v);
        },
        closeForm() {
            //恢复初始状态
            this.flowPanel = true;
            this.showHandel = false;
            this.showInfo = false;
            this.showReview = false;
            this.showNotice = false;
            this.getData(this.type);
        },
        showCreateWin() {
            this.flows;
            this.showCreateFlow = true;
        },
        handleSelect(key) {
            this.type = key;
            this.getData(key);
        },
        openInfo(row) {
            this.showInfo = true;
            this.flowPanel = false;
            this.$nextTick(() => {
                this.$refs.info_win.init(row);
            });
        },
        handelFlow(row) {
            this.currentRow = row;
            this.flowApplyID = row.id;
            switch (row.taskType) {
                case 0:
                    this.flowAction = 0;
                    this.flowPanel = false;
                    this.showNotice = true;
                    this.$nextTick(() => {
                        this.$refs.notice_win.init(row);
                    });
                    break;
                case 1:
                    this.showHandel = true;
                    this.flowAction = 1;
                    this.flowPanel = false;
                    this.$nextTick(() => {
                        this.$refs.handel_win.handle(row);
                    });
                    break;
                case 3:
                    this.showHandel = true;
                    this.flowAction = 3;
                    this.flowPanel = false;
                    this.$nextTick(() => {
                        this.$refs.handel_win.handle(row);
                    });
                    break;
                case 2:
                    this.showReview = true;
                    this.flowAction = 2;
                    this.flowPanel = false;
                    this.$nextTick(() => {
                        this.$refs.review_win.init(row);
                    });
                    break;
                default:
                    break;
            }
        },
        getTxt(value) {
            if (value && this.flowGroupMap) {
                return this.flowGroupMap.filter((n) => n.itemKey === value + '')[0].itemValue;
            }
            return '';
        },
        async getInit() {
            const dictMap = [
                {parentCode: 'flow_group', listName: 'flowGroupMap'},
                {parentCode: 'flow_business_apply_status', listName: 'businessstatusList'},
                /*修改*/
                {parentCode: 'flow_drawing_apply_status', listName: 'drawingstatusList'},
                {parentCode: 'flow_emergency_repair_status', listName: 'equipmentstatusList'},
                /*{parentCode: 'flow_overhaul_status', listName: 'overhaulstatusList'},
                    {parentCode: 'form_overhaul_1_status', listName: 'overhaulstatusList1'},
                    {parentCode: 'flow_put_into_production_status', listName: 'putIntoProductionStatusList'},*/
            ];
            this.$client.getDicDataArr(dictMap.map((it) => it.parentCode)).then((res) => {
                let {data: resData} = res;
                for (let key in resData) {
                    let data = resData[key];
                    let dict = dictMap.filter((it) => it.parentCode === key)[0];
                    this[dict.listName] = data;
                }
            });
            this.getRoleFlow();

            // const data = await Promise.all([
            //     this.$client.getDicData('flow_group'),
            //     this.getRoleFlow(),
            //     this.$client.getDicData('business_status'),
            // ]);
            // this.flowGroupMap = data[0].data || [];
            // //this.flowList = data[1].list || [];
            // this.statusList = data[2].data || [];
        },
        async initFormValue() {
            await this.getData(this.type);
            await this.getInit();
        },
        closePanel() {
            this.showStepInfo = false;
        },
        clickStep(index) {
            this.showStepIndex = index;
            this.showStepInfo = true;
        },
        deleteFlow(id) {
            // todo 对接删除
            this.$confirm('确定删除操作吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.deleteWorkflow(id).then(() => {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500
                    });
                    this.getData(this.type);
                });
            });
        },
        initHandle() {
            let {act, obj} = this.$route.params;
            if (act) {
                switch (act) {
                    case 1:
                        //业扩变更
                        this.editFlow(obj);
                        break;
                }
            }
        },
    },
    activited() {
    },
    created() {
        this.initFormValue();
        this.initHandle();
    },
};
</script>

<style lang="less" scoped>
    @import '~@/assets/style/variables.less';
    .grid-content {
        .el-select--mini,
        .el-input--mini {
            width: 150px;
            margin-right: 10px;
        }
    }

    .def-page-foot {
        .el-pagination {
            position: absolute;
            top: calc(50% + 10px);
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .load-workflow-step{
        background-color: @bg-conent;
        height: 100%;
        overflow: hidden scroll;
        // /deep/ .el-card{
        //     background-color: @bg-conent;
        // }
        /deep/  .card-pub-notice{
            background-color: @bg-conent;
            margin-top: 10px;
            .card-pub-notice{
                background-color: @bg-light;
                margin-top: 10px;
                .card-pub-notice{
                    background-color: @bg-conent;
                    margin-top: 10px;
                }
            }
        }
        /deep/ .submit-btn-group{
            text-align: center;
            margin-top: 60px;
            margin-bottom: 60px;
        }
        /deep/ .el-card__header{
            padding-left: 10px;
        }
    }
    .workflow-list {
        position: relative;
        height: calc(100% - 10px);
        &__action-btn{
            float:right;
            & + &{
                margin-left: 10px;
            }
        }
        &__notice-count{
            background-color: #FF7260;
            color: #fff;
            border-radius: 10px;
            font-size: 12px;
            padding: 0 5px;
            position: relative;
            top: -8px;
        }
        &__card-head-border {
            border-left: 3px solid @bg-btn-light;
            padding-left: 10px;
        }
        &__card-head-type {
            margin-left: 30px;
        }
        &__item-card {
            background-color: @bg-dark;
            & + & {
                margin-top: 20px;
            }
        }
        &__nav-form {
            padding: 10px;
            padding-bottom: 20px;
        }
        &__wrap {
            background-color: @bg-light;
            height: 100%;
            width: 100%;
        }
        &__content {
            height: calc(100% - 140px);
            overflow: auto;
            padding: 20px;
            box-sizing: border-box;
        }
        &__foot {
            height: 35px;
        }
        &__header {
            height: 105px;
            background-color: @bg-dark;
            border-bottom: 1px solid @bg-border;
        }
        &__navbar {
            border-bottom: 1px solid @bg-border;
            padding-top: 10px;
            /deep/ .el-menu {
                background-color: transparent;
            }
            /deep/ .el-menu.el-menu--horizontal {
                height: 30px;
                border: none;
                margin-right: 0;
                margin-bottom: 0;
            }
            /deep/ .el-menu--horizontal > .el-menu-item {
                height: 35px;
                line-height: 35px;
            }
            /deep/ .el-header {
                padding: 0;
            }
            /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
            /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
            /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
                background-color: lighten(@bg-dark, 22%);
            }
            /deep/ .el-menu--horizontal > .el-menu-item.is-active {
                border-color: #ededfd;
            }
        }
        /deep/ .el-card__header {
            padding: 15px 20px;
        }
        /deep/ .el-card__body {
            padding: 15px 20px;
            font-size: 14px;
        }
    }
</style>
