<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="24">
                    <ex-uploader v-model="formData.files" :limit="formData.requiredNum" :singleFileMode="formData.requiredNum===1"></ex-uploader>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'DatumImg',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    datumName: '',
                    modelType: '',
                    createTime: '',
                    datumCode: '',
                    required: 0,
                    requiredNum: 0,
                    updateTime: '',
                    files: []
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            imageUploadUrl: this.$client.uploadFileUrl,
            keyValues: {
            },
            uploadHeaders: {
                token: this.$store.state.token
            },
            fileList: []
        };
    },
    computed: {
        formRules() {
            return {};
        }
    },
    methods: {
        getDatumFiles() {
            let files = [];
            this.fileList.forEach(e => {
                if (e.response && e.response.data) {
                    files.push(e.response.data);
                }
            });
            return {
                datumModelId: this.formData.id,
                ossIds: [],
                files
            };
        },
        async bindKeyValues() {

        },
        async initFormValue() {
            await this.bindKeyValues();
        },
        handleRemove(file, fileList) {
        },
        imgHandleonProgress() {
            this.loading = true;
        },
        imgHandleExceed() {
            this.$message({
                showClose: true,
                message: '文件超出限制！',
                type: 'error'
            });
        },
        datumImg(response, file, fileList) {
            this.fileList = fileList;
            this.loading = false;
        },
        handlePictureCardPreview(file) {
            this.$emit('previewImage', file.url);
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
</style>


