<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="$t('businessApply.form.declarationNote')" prop="declarationNote">
                        <el-input type="textarea" :rows="4" v-model="formData.declarationNote"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'DeclarationNote',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    declarationNote: '',
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
            },
        };
    },
    computed: {},
    methods: {
        async bindKeyValues() {

        },
        async initFormValue() {
            await this.bindKeyValues();
        },
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
</style>


