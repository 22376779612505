<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="$t('businessApply.form.businessType')" prop="businessType">
                         <el-radio-group v-model="formData.businessType">
                              <el-radio v-for="item in keyValues.businessType" :label="item.itemKey" :key="item.itemKey">{{item.itemValue}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item :label="$t('businessApply.form.voltage')" prop="voltage">
                         <el-radio-group v-model="formData.voltage">
                              <el-radio v-for="item in keyValues.voltage" :label="parseInt(item.itemKey)" :key="parseInt(item.itemKey)">{{item.itemValue}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item :label="$t('businessApply.form.powerRequire')" prop="powerRequire">
                         <el-radio-group v-model="formData.powerRequire">
                              <el-radio v-for="item in keyValues.powerRequire" :label="parseInt(item.itemKey)" :key="parseInt(item.itemKey)">{{item.itemValue}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="$t('businessApply.form.elecNature')" prop="elecNature">
                         <el-radio-group v-model="formData.elecNature">
                              <el-radio v-for="item in keyValues.elecNature" :label="parseInt(item.itemKey)" :key="parseInt(item.itemKey)">{{item.itemValue}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item v-if="formData.elecNature===2" :label="$t('businessApply.form.tempDate')" prop="tempDate">
                         <el-date-picker
                            value-format="yyyy-MM-dd"
                            v-model="formData.tempDate"
                            type="daterange"
                            :range-separator="$t('common.range-separator')"
                            :start-placeholder="$t('common.start-month')"
                            :end-placeholder="$t('common.end-month')">
                         </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="$t('businessApply.form.loadLevel')" prop="loadLevel">
                         <el-radio-group v-model="formData.loadLevel">
                              <el-radio v-for="item in keyValues.loadLevel" :label="parseInt(item.itemKey)" :key="parseInt(item.itemKey)">{{item.itemValue}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="6">
                    <el-form-item :label="$t('businessApply.form.elecType')" prop="elecType">
                         <el-select v-model="formData.elecType">
                          <el-option v-for="item in keyValues.elecType"
                                     :key="parseInt(item.itemKey)"
                                     :value="parseInt(item.itemKey)"
                                     :label="item.itemValue"></el-option>
                      </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item :label="$t('businessApply.form.oldCapacity')" prop="oldCapacity">
                         <el-input-number style="width: 100%;" :min="0" v-model="formData.oldCapacity"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item :label="$t('businessApply.form.updateCapacity')" prop="updateCapacity">
                         <el-input-number  style="width: 100%;" :min="0" v-model="formData.updateCapacity"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item :label="$t('businessApply.form.totalCapacity')" prop="totalCapacity">
                         <el-input readOnly v-model="formData.totalCapacity"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'BusinessContent',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    businessType: '',
                    voltage: '',
                    powerRequire: '',
                    elecNature: '',
                    tempDate: '',
                    loadLevel: '',
                    elecType: '',
                    oldCapacity: '',
                    updateCapacity: '',
                    totalCapacity: '',
                    tempStartDate: '',
                    tempEndDate: ''
                };
            }
        }
    },
    watch: {
        formData: {
            handler(newVal) {
                if (newVal.oldCapacity >= 0 && newVal.updateCapacity >= 0) {
                    newVal.totalCapacity = Number(newVal.oldCapacity) + Number(newVal.updateCapacity);
                }
                if (newVal.tempDate != null && newVal.tempDate.length === 2) {
                    newVal.tempStartDate = newVal.tempDate[0];
                    newVal.tempEndDate = newVal.tempDate[1];
                } else {
                    newVal.tempStartDate = null;
                    newVal.tempEndDate = null;
                }
            },
            deep: true
        }
    },
    data() {
        return {
            loading: false,
            ickerOptions: {
                shortcuts: [{
                    text: this.$t('common.current-month'),
                    onClick(picker) {
                        picker.$emit('pick', [new Date(), new Date()]);
                    }
                }, {
                    text: this.$t('common.current-year-to-now'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date(new Date().getFullYear(), 0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.$t('common.lastest-six-month'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(start.getMonth() - 6);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            formRules: {
                businessType: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.businessType') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                voltage: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.voltage') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                powerRequire: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.powerRequire') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                elecNature: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.elecNature') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                tempDate: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.tempDate') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                loadLevel: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.loadLevel') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                elecType: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.elecType') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                oldCapacity: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.oldCapacity') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                updateCapacity: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.updateCapacity') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ],
                totalCapacity: [
                    {
                        required: true,
                        message: this.$t('businessApply.form.totalCapacity') + ' ' + this.$t('common.required'),
                        trigger: 'change'
                    }
                ]
            },
            keyValues: {
                businessType: [],
                voltage: [],
                powerRequire: [],
                elecNature: [],
                loadLevel: [],
                elecType: []
            }
        };
    },
    computed: {},
    methods: {
        async bindKeyValues() {
            const dictMap = [
                {parentCode: 'business_type', listName: 'businessType'},
                {parentCode: 'voltage', listName: 'voltage'},
                {parentCode: 'power_require', listName: 'powerRequire'},
                {parentCode: 'elec_nature', listName: 'elecNature'},
                {parentCode: 'load_level', listName: 'loadLevel'},
                {parentCode: 'elec_type', listName: 'elecType'},
            ];
            this.$client.getDicDataArr(dictMap.map((it) => it.parentCode)).then((res) => {
                let {data: resData} = res;
                for (let key in resData) {
                    let data = resData[key];
                    let dict = dictMap.filter((it) => it.parentCode === key)[0];
                    this.keyValues[dict.listName] = data;
                }
            });
            // const data = await Promise.all([
            //     this.$client.getDicData('business_type'),
            //     this.$client.getDicData('voltage'),
            //     this.$client.getDicData('power_require'),
            //     this.$client.getDicData('elec_nature'),
            //     this.$client.getDicData('load_level'),
            //     this.$client.getDicData('elec_type'),
            // ]);
            // this.keyValues.businessType = data[0].data || [];
            // this.keyValues.voltage = data[1].data || [];
            // this.keyValues.powerRequire = data[2].data || [];
            // this.keyValues.elecNature = data[3].data || [];
            // this.keyValues.loadLevel = data[4].data || [];
            // this.keyValues.elecType = data[5].data || [];
        },
        async initFormValue() {
            await this.bindKeyValues();
        },
    },
    created() {
        this.initFormValue();
    }
};
</script>


<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>
