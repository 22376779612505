<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item prop="importantLoadPower" label="">
                        <el-radio v-model="formData.importantLoadPower" v-for="i in keyValues.importantLoadPower" :label="parseInt(i.itemKey)" :key="parseInt(i.itemKey)">{{i.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'ImportantLoadPowerInfo',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    importantLoadPower: ''
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                importantLoadPower: [
                    {
                        required: true,
                        message: '重要负荷应急电源配置 ' + '必填',
                        trigger: 'change'
                    }
                ],
            },
            keyValues: {
                importantLoadPower: [],
            }
        };
    },
    methods: {
        async bindKeyValues() {
            const data = await Promise.all([
                this.$client.getDicData('important_load_power'),
            ]);
            this.keyValues.importantLoadPower = data[0].data || [];
        },
    },
    created() {
        this.bindKeyValues();
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>


