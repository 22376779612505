<template>
    <!-- 勘测阶段 -->
    <div>
        <step-bar :active="2"></step-bar>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>《电力客户供电方案勘察表》</span>
            </div>
            <div class="commonNotice">
                <div class="ul-center-box" style="font-size: 13px">
                    <!-- 业务信息 -->
                    <ex-card headerName="业务信息">
                        <div class="commonNotice">
                            <business-information
                                ref="form1"
                                :subChooseBar="false"
                                :formData="businessInformation"
                                class="commonNotice"
                            ></business-information>
                        </div>
                    </ex-card>

                    <!-- 接入方式 -->
                    <ex-card headerName="接入方式">
                        <div class="commonNotice">
                            <access-method ref="form2" :formData="accessMethod" class="commonNotice"></access-method>
                        </div>
                    </ex-card>

                    <!-- 初步接入方式 -->
                    <ex-card headerName="初步接入方案">
                        <div class="commonNotice">
                            <div v-for="(item, index) in accessPlanList" :key="index">
                                <span>{{ item.planName + index }}</span>
                                <div>{{ item.powerPointDescription }}</div>
                                <div>{{ item.capacity }}</div>
                            </div>
                        </div>
                    </ex-card>

                    <!-- 现场勘察情况 -->
                    <ex-card headerName="现场勘察情况">
                        <div class="commonNotice">
                            <access-method
                                ref="form3"
                                :formData="onSiteInvestigation"
                                class="commonNotice"
                            ></access-method>
                        </div>
                    </ex-card>

                    <!-- 推荐电源点选择原因 -->
                    <ex-card headerName="推荐电源点选择原因">
                        <div class="commonNotice">
                            <access-method
                                ref="form4"
                                :formData="reasonsPowerPointSelection"
                                class="commonNotice"
                            ></access-method>
                        </div>
                    </ex-card>

                    <!-- 勘察意见 -->
                    <ex-card headerName="勘察意见">
                        <div class="commonNotice">
                            <access-method ref="form5" :formData="surveyOpinion" class="commonNotice"></access-method>
                        </div>
                    </ex-card>
                </div>
            </div>
        </el-card>

        <el-row :gutter="24" style="margin-top: 20px">
            <el-col :offset="10" :span="8">
                <el-button @click="turnDown">驳回</el-button>
                <el-button @click="save" type="primary">同意</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import StepBar from '@/components/StepBar/index';
import BusinessInformation from '../reconnaissance/businessInformation';
import AccessMethod from './accessMethod';
export default {
    name: 'PowerSupplyPlan',
    components: {
        StepBar,
        BusinessInformation,
        AccessMethod,
    },
    props: {
        id: {
            type: String,
            default: '0',
        },
    },
    // computed: {
    //     total1() {
    //         var total = 0;
    //         for (const item of this.equipmentList) {
    //             total += item.capacityCount;
    //         }
    //         return `${total}(kW)`;
    //     }
    // },
    data() {
        return {
            businessInformation: {},
            accessMethod: {},
            accessPlanList: [],
            onSiteInvestigation: {},
            reasonsPowerPointSelection: {},
            surveyOpinion: {},
            loading: false,
            // 折叠卡片包
            planFold: {
                businessInformation: false,
                accessMethod: false,
                initialAccessPlan: false,
                onSiteInvestigation: false,
                reasonsPowerPointSelection: false,
                surveyOpinion: false,
            },
        };
    },
    methods: {
        // TODO 取得
        async initFormValue() {},
        // card 折叠方法
        shrinkComponents(name) {
            try {
                this.planFold[name] = !this.planFold[name];
            } catch (e) {
                console.log('shrinkComponents error');
            }
        },
        save() {
            // 验证
            this.closeElement();
        },
        turnDown() {},
        // 关闭窗体
        closeElement() {
            this.$emit('closePanel');
        },
    },
    created() {
        this.initFormValue();
    },
};
</script>

<style lang="less" scoped>
</style>
