<template>
    <div>
        <step-bar :active="3"></step-bar>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>验收申请文件</span>
            </div>
            <div class="commonNotice">
                <div class="content">
                    <el-row :gutter="24">
                        <el-col :span="8">
                            <div class="uploader">
                                <ex-uploader v-model="completionApplyFiles" :singleFileMode='true' :disabled="true"></ex-uploader>
                                <br/>
                                <span>竣工检验申请书</span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="uploader">
                                <ex-uploader v-model="completionAskCheckFiles" :singleFileMode='true' :disabled="true"></ex-uploader>
                                <br/>
                                <span>竣工报验单</span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="uploader">
                                <ex-uploader v-model="completionCheckFiles" :singleFileMode='true' :disabled="true"></ex-uploader>
                                <br/>
                                <span>竣工资料审核单</span>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-card>

        <el-row v-if="showBack" :gutter="24" class="submit-btn-group">
        <el-col :span="24">
            <el-button @click="back" style="position: absolute;left:0; top:0; right:0; bottom: 0; margin: auto;height:26px;">返回</el-button>
        </el-col>
        </el-row>

    </div>
</template>

<script>
import axios from '@/client/axios';
import StepBar from '@/components/StepBar/index';
export default {
    name: 'completionApplyShow',
    components: {
        StepBar
    },
    props: {
        id: {
            type: [String, Number],
            default: '0'
        },
        showBack: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            completionApplyFiles: [],
            completionAskCheckFiles: [],
            completionCheckFiles: []
        };
    },
    methods: {
        loadData() {
            this.$client.getCompletionApply(this.id).then(data => {
                for (let i = 0; i < data.data.completionFiles.length; i++) {
                    let item = data.data.completionFiles[i];
                    if (item.remark === '竣工检验申请书') {
                        this.completionApplyFiles = item.files;
                    } else if (item.remark === '竣工报验单') {
                        this.completionAskCheckFiles = item.files;
                    } else if (item.remark === '竣工资料审核单') {
                        this.completionCheckFiles = item.files;
                    }
                }
            });
        },
        back() {
            this.$emit('closePanel');
        }
    },
    async created() {
        await this.loadData();
    }
};
</script>

<style lang="less" scoped>
.card-ext-center-title {
    .commonNotice {
        line-height: 2.1;
    }
}
</style>
