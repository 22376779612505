<template>
    <div>
        <step-bar :active="0" @clickStep="clickStep" :forward="!showBack"></step-bar>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>非居民客户新装、增/减容用电业务申请表</span>
            </div>
            <div class="commonNotice">
                <ex-card headerName="用电方信息">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24" v-for="(item,index) in customerInfo" :key="index">
                            <el-col :span="6" style="text-align:right;">
                                <span>{{item.name}}:</span>
                            </el-col>
                            <el-col :span="18" style="text-align:left;">
                                <span>{{item.value}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
                <ex-card headerName="业务内容">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24" v-for="(item,index) in businessContent" :key="index">
                            <el-col :span="6" style="text-align:right;">
                                <span>{{item.name}}:</span>
                            </el-col>
                            <el-col :span="18" style="text-align:left;">
                                <span>{{item.value}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
                <ex-card headerName="电费结算">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24" v-for="(item,index) in elecBill" :key="index">
                            <el-col :span="6" style="text-align:right;">
                                <span>{{item.name}}:</span>
                            </el-col>
                            <el-col :span="18" style="text-align:left;">
                                <span>{{item.value}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
                <ex-card headerName="增值税开票资料">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24" v-for="(item,index) in invoiceInfo" :key="index">
                            <el-col :span="6" style="text-align:right;">
                                <span>{{item.name}}:</span>
                            </el-col>
                            <el-col :span="18" style="text-align:left;">
                                <span>{{item.value}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
                <!--<ex-card headerName="申请说明">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24">
                            <el-col :span="24" style="text-align:left;">
                                <span>{{declarationNote}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>-->
            </div>
        </el-card>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>用电设备容量清单</span>
            </div>
            <div class="commonNotice">
                <ex-card>
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24">
                            <el-col :span="24" style="text-align:left;max-height:220px;overflow-y:scroll;">
                                    <el-row :gutter="24">
                                        <template v-for="(item,index) in equipments">
                                        <el-col :span="2" :key="index+0.1" v-if="index%5===0">
                                        </el-col>
                                        <el-col :span="4" :key="index">
                                            <el-card class="card-pub-notice equipment">
                                                <div class="commonNotice">
                                                    <div class="el-card__subheader">
                                                        <div class="clearfix">
                                                            <span>{{'设备'+(index+1)}}</span>
                                                            <div class="triangle_border_up">
                                                                <span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <el-row :gutter="4" v-for="(item1,index1) in getEquipmentInfo(item)" :key="index1">
                                                        <el-col :span="15" style="text-align:right;">
                                                            <span>{{item1.name}}:</span>
                                                        </el-col>
                                                        <el-col :span="9" style="text-align:left;">
                                                            <span>{{item1.value}}</span>
                                                        </el-col>
                                                    </el-row>
                                                </div>
                                            </el-card>
                                        </el-col>
                                        <el-col :span="2" :key="index+0.2" v-if="(index+1)%5===0">
                                        </el-col>
                                        </template>
                                    </el-row>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>

                <el-row :gutter="24">
                    <el-col :span="12" style="text-align:right;">用电负荷合计:<span style="color:gray;">{{total1}}</span></el-col>
                    <el-col :span="12">装见容量合计:<span style="color:gray;">{{equipmentTotalCapacity}}(Kw)</span></el-col>
                </el-row>
            </div>
        </el-card>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>资料清单</span>
            </div>
            <div class="commonNotice">
                <el-card class="card-pub-notice card-ext-center-title" :headerName="item.datumName" v-for="item in datumImg" :key="item.datumModelId">
                    <div slot="header" class="notice_title">
                        <span>{{item.datumName}}</span>
                    </div>
                    <ex-uploader v-model="item.files" :disabled=true></ex-uploader>
                </el-card>
            </div>
        </el-card>

        <el-row v-if="showBack" :gutter="24" class="submit-btn-group">
        <el-col :span="24">
            <el-button @click="back" style="position: absolute;left:0; top:0; right:0; bottom: 0; margin: auto;height:26px;">返回</el-button>
        </el-col>
    </el-row>
    </div>
</template>

<script>
import axios from '@/client/axios';
import StepBar from '@/components/StepBar/index';
export default {
    name: 'BusinessApplyShow',
    components: {
        StepBar
    },
    props: {
        id: {
            type: [String, Number],
            default: '0'
        },
        showBack: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        total1() {
            let total = 0;
            for (const item of this.equipments) {
                total += item.capacityCount;
            }
            return `${total}(kW)`;
        },
    },
    data() {
        return {
            customerInfo: [],
            businessContent: [],
            elecBill: [],
            invoiceInfo: [],
            declarationNote: '',
            equipments: [],
            equipmentTotalCapacity: 0,
            datumImg: [],
            keyValues: {
                customerIdentity: [],
                customerIndustry: [],
                customerType: [],
                elecAssureType: [],
                businessType: [],
                voltage: [],
                powerRequire: [],
                elecNature: [],
                loadLevel: [],
                elecType: [],
                payType: [],
                invoiceType: [],
            },
        };
    },
    methods: {
        async bindKeyValues() {
            const dictMap = [
                {parentCode: 'customer_identity', listName: 'customerIdentity'},
                {parentCode: 'customer_industry', listName: 'customerIndustry'},
                {parentCode: 'customer_type', listName: 'customerType'},
                {parentCode: 'elec_assure_type', listName: 'elecAssureType'},
                {parentCode: 'business_type', listName: 'businessType'},
                {parentCode: 'voltage', listName: 'voltage'},
                {parentCode: 'power_require', listName: 'powerRequire'},
                {parentCode: 'elec_nature', listName: 'elecNature'},
                {parentCode: 'load_level', listName: 'loadLevel'},
                {parentCode: 'elec_type', listName: 'elecType'},
                {parentCode: 'pay_type', listName: 'payType'},
                {parentCode: 'invoice_type', listName: 'invoiceType'},
            ];
            this.$client.getDicDataArr(dictMap.map((it) => it.parentCode)).then((res) => {
                let {data: resData} = res;
                for (let key in resData) {
                    let data = resData[key];
                    let dict = dictMap.filter((it) => it.parentCode === key)[0];
                    this.keyValues[dict.listName] = data;
                }
            });
            // const data = await Promise.all([
            //     this.$client.getDicData('customer_identity'),
            //     this.$client.getDicData('customer_industry'),
            //     this.$client.getDicData('customer_type'),
            //     this.$client.getDicData('elec_assure_type'),
            //     this.$client.getDicData('business_type'),
            //     this.$client.getDicData('voltage'),
            //     this.$client.getDicData('power_require'),
            //     this.$client.getDicData('elec_nature'),
            //     this.$client.getDicData('load_level'),
            //     this.$client.getDicData('elec_type'),
            //     this.$client.getDicData('pay_type'),
            //     this.$client.getDicData('invoice_type'),
            // ]);
            // this.keyValues.customerIdentity = data[0].data || [];
            // this.keyValues.customerIndustry = data[1].data || [];
            // this.keyValues.customerType = data[2].data || [];
            // this.keyValues.elecAssureType = data[3].data || [];
            // this.keyValues.businessType = data[4].data || [];
            // this.keyValues.voltage = data[5].data || [];
            // this.keyValues.powerRequire = data[6].data || [];
            // this.keyValues.elecNature = data[7].data || [];
            // this.keyValues.loadLevel = data[8].data || [];
            // this.keyValues.elecType = data[9].data || [];
            // this.keyValues.payType = data[10].data || [];
            // this.keyValues.invoiceType = data[11].data || [];
        },
        loadData() {
            this.$client.getBusinessInfo(this.id).then((data) => {
                // 用电方信息
                this.customerInfo.push({name: '客户名称', value: data.data.customerName});
                this.customerInfo.push({name: '用电地址', value: data.data.useAddr});
                this.customerInfo.push({name: '通邮地址', value: data.data.contactAddr});
                this.customerInfo.push({name: '邮政编号', value: data.data.zipCode});
                this.customerInfo.push({name: '传真', value: data.data.faxes});
                let items = this.keyValues.customerIdentity.filter(e => parseInt(e.itemKey) === data.data.customerIdentity);
                this.customerInfo.push({name: '客户身份', value: items.length > 0 ? items[0].itemValue : data.data.customerIdentity});
                this.customerInfo.push({name: '客户身份证号', value: data.data.idNumber});
                this.customerInfo.push({name: '业务经办人', value: data.data.agent});
                this.customerInfo.push({name: '联系电话', value: data.data.agentTel});
                items = this.keyValues.customerType.filter(e => parseInt(e.itemKey) === data.data.customerType);
                this.customerInfo.push({name: '客户类型', value: items.length > 0 ? items[0].itemValue : data.data.customerType});
                items = this.keyValues.customerIndustry.filter(e => data.data.customerIndustry.indexOf(e.itemKey) > -1).map(e => e.itemValue);
                this.customerInfo.push({name: '客户类别', value: items.length > 0 ? items.join(',') : data.data.customerIndustry});
                this.customerInfo.push({name: '用电联系人', value: data.data.useRelName});
                this.customerInfo.push({name: '联系电话', value: data.data.useRelTel});
                this.customerInfo.push({name: '电费联系人', value: data.data.chargeRelName});
                this.customerInfo.push({name: '联系电话', value: data.data.chargeRelTel});
                this.customerInfo.push({name: '接收邮箱地址', value: data.data.billEmail});
                items = this.keyValues.elecAssureType.filter(e => parseInt(e.itemKey) === data.data.elecAssureType);
                this.customerInfo.push({name: '电费担保方式(租赁户)', value: items.length > 0 ? items[0].itemValue : data.data.elecAssureType});

                // 业务内容
                items = this.keyValues.businessType.filter(e => e.itemKey === data.data.businessType);
                this.businessContent.push({name: '业务类型', value: items.length > 0 ? items[0].itemValue : data.data.businessType});
                items = this.keyValues.voltage.filter(e => parseInt(e.itemKey) === data.data.voltage);
                this.businessContent.push({name: '供电电压', value: items.length > 0 ? items[0].itemValue : data.data.voltage});
                items = this.keyValues.powerRequire.filter(e => parseInt(e.itemKey) === data.data.powerRequire);
                this.businessContent.push({name: '电源要求', value: items.length > 0 ? items[0].itemValue : data.data.powerRequire});
                items = this.keyValues.elecNature.filter(e => parseInt(e.itemKey) === data.data.elecNature);
                this.businessContent.push({name: '用电性质', value: items.length > 0 ? items[0].itemValue : data.data.elecNature});
                items = this.keyValues.loadLevel.filter(e => parseInt(e.itemKey) === data.data.loadLevel);
                this.businessContent.push({name: '负荷级别', value: items.length > 0 ? items[0].itemValue : data.data.loadLevel});
                items = this.keyValues.elecType.filter(e => parseInt(e.itemKey) === data.data.elecType);
                this.businessContent.push({name: '用电类别', value: items.length > 0 ? items[0].itemValue : data.data.elecType});
                this.businessContent.push({name: '原有容量', value: data.data.oldCapacity});
                this.businessContent.push({name: '增/减容量', value: data.data.updateCapacity});
                this.businessContent.push({name: '容量合计', value: data.data.totalCapacity});

                // 电费结算
                items = this.keyValues.payType.filter(e => parseInt(e.itemKey) === data.data.payType);
                this.elecBill.push({name: '缴费方式', value: items.length > 0 ? items[0].itemValue : data.data.payType});
                this.elecBill.push({name: '账户名称', value: data.data.accountName});
                this.elecBill.push({name: '银行名称', value: data.data.bankName});
                this.elecBill.push({name: '发票账号', value: data.data.invoiceAccount});

                // 增值税开票资料
                items = this.keyValues.invoiceType.filter(e => parseInt(e.itemKey) === data.data.invoiceType);
                this.invoiceInfo.push({name: '发票类型', value: items.length > 0 ? items[0].itemValue : data.data.invoiceType});
                this.invoiceInfo.push({name: '纳税人名称', value: data.data.taxpayerName});
                this.invoiceInfo.push({name: '税号', value: data.data.taxNumber});
                this.invoiceInfo.push({name: '账号', value: data.data.account});
                this.invoiceInfo.push({name: '开户银行', value: data.data.bank});

                // 申请说明
                this.declarationNote = data.data.note;

                // 用电设备容量清单
                this.equipments = data.data.equipmentCapacityForms;
                this.equipmentTotalCapacity = data.data.equipmentTotalCapacity;

                // 资料清单
                this.datumImg = data.data.businessDatumForms;
            });
        },
        getEquipmentInfo(data) {
            return [
                {name: '设备名称', value: data.equipmentName},
                {name: '设备型号', value: data.equipmentType},
                {name: '设备数量', value: data.num},
                {name: '额定电压', value: data.nominalVoltage},
                {name: '单台容量', value: data.singleCapacity},
                {name: '容量小计', value: data.capacityCount},
            ];
        },
        back() {
            this.$emit('closePanel');
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        }
    },
    async created() {
        await this.bindKeyValues();
        await this.loadData();
    }
};
</script>

<style lang="less" scoped>
.equipment .el-card__body{
    padding: 0;
}
</style>
