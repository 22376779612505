<template>
    <div>
        <step-bar :active="2" @clickStep="clickStep"></step-bar>
        <ex-card headerName="审核意见" v-if="reviewRecords.length>0">
            <div class="commonNotice">
                <div class="ulbox">
                    <el-row :gutter="24">
                        <el-col :span="24" style="text-align:left;" v-for="(item,index) in reviewRecords" :key="index">
                            <p>{{$dayjs(item.reviewTime).format('YYYY-MM-DD HH:mm')}}审核:
                                <span :style="{color:item.reviewStatus==1?'green':'red'}">{{item.reviewStatus==1?'通过':'不通过'}}</span>
                            </p>
                            <p>{{item.reviewNote}}</p>
                            <p style="text-align:right">审核人:{{item.reviewBy}}</p>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </ex-card>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>新区配售电有限公司供电方案审批流程单</span>
            </div>
            <div class="commonNotice">
                <ex-card headerName="客户基本信息">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24" v-for="(item,index) in customerInfo" :key="index">
                                <el-col :span="6" style="text-align:right;">
                                    <span>{{item.name}}:</span>
                                </el-col>
                                <el-col :span="18" style="text-align:left;">
                                    <span>{{item.value}}</span>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="电源容量信息">
                    <power-capacity-info ref="form1" :formData="powerCapacityInfo" class="commonNotice"></power-capacity-info>
                </ex-card>
                <ex-card headerName="计量配置">
                    <div class="commonNotice">
                        <metering-info ref="form2" v-for="(item,index) in meteringInfo.meterings" :key="index" :index="index" :formData="item"></metering-info>
                    </div>
                </ex-card>
                <ex-card headerName="电价执行">
                    <div class="commonNotice">
                        <elec-charge-info ref="form3" v-for="(item,index) in elecChargeInfo.charges" :key="index" :index="index" :formData="item"></elec-charge-info>
                    </div>
                </ex-card>
                <ex-card headerName="重要负荷应急电源配置">
                    <important-load-power-info ref="form4" :formData="importantLoadPowerInfo" class="commonNotice"></important-load-power-info>
                </ex-card>
                <ex-card headerName="业扩费用">
                    <business-fee-info ref="form5" :formData="businessFeeInfo" class="commonNotice"></business-fee-info>
                </ex-card>
                <ex-card headerName="工程设计">
                    <design-info ref="form6" :formData="designInfo" class="commonNotice"></design-info>
                </ex-card>
                <ex-card headerName="业扩投资界面延伸">
                    <investment-interface-info ref="form7" :formData="investmentInterfaceInfo" class="commonNotice"></investment-interface-info>
                </ex-card>
                <ex-card headerName="中间检查">
                    <middle-check-info ref="form8" :formData="middleCheckInfo" class="commonNotice"></middle-check-info>
                </ex-card>
                <ex-card headerName="资产移交">
                    <asset-transfer-info ref="form9" :formData="assetTransferInfo" class="commonNotice"></asset-transfer-info>
                </ex-card>
                <ex-card headerName="竣工检验">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24">
                                <el-col :span="24" style="text-align:left;">
                                    <span>配电工程完成施工时请及时提交竣工检验申请。</span>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="*产权分界点示意图">
                    <ex-uploader ref="form10" v-model="supplyDiagramFiles"></ex-uploader>
                </ex-card>
                <ex-card headerName="是否带电作业">
                    <hot-line-work-info ref="form11" :formData="hotLineWorkInfo" class="commonNotice"></hot-line-work-info>
                </ex-card>
                <ex-card headerName="其他">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24">
                                <el-col :span="24" style="text-align:left;">
                                    <div>
                                        1.本方案未尽事宜，双方均按《电力供应与使用条例》和《供电营业规则》等法规执行。
                                    </div>
                                    <div>
                                        2.本方案有效期为一年，从供电企业出具供电方案答复通知书之日起，至客户受电工程开工之日为止。
                                    </div>
                                    <div>
                                        3.本方案一式两份，甲、乙双方各执一份，两份具有同等效力。
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
            </div>
        </el-card>

        <el-row :gutter="24" class="submit-btn-group">
            <el-col :span="8" :offset="8">
                <el-row :gutter="24">
                    <el-col :span="8">
                        <el-button @click="cancel">取消</el-button>
                    </el-col>
                    <el-col :span="8">
                        <el-button @click="save">保存</el-button>
                    </el-col>
                    <el-col :span="8">
                        <el-button @click="submit">保存并发起</el-button>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import PowerCapacityInfo from './powerCapacityInfo';
import StepBar from '@/components/StepBar/index';
import MeteringInfo from './meteringInfo';
import ElecChargeInfo from './elecChargeInfo';
import ImportantLoadPowerInfo from './importantLoadPowerInfo';
import BusinessFeeInfo from './businessFeeInfo';
import DesignInfo from './designInfo';
import InvestmentInterfaceInfo from './investmentInterfaceInfo';
import MiddleCheckInfo from './middleCheckInfo';
import AssetTransferInfo from './assetTransferInfo';
import HotLineWorkInfo from './hotLineWorkInfo';
export default {
    name: 'ElectricPlanForm',
    components: {
        StepBar,
        PowerCapacityInfo,
        MeteringInfo,
        ElecChargeInfo,
        ImportantLoadPowerInfo,
        BusinessFeeInfo,
        DesignInfo,
        InvestmentInterfaceInfo,
        MiddleCheckInfo,
        AssetTransferInfo,
        HotLineWorkInfo
    },
    props: {
        id: {
            type: [Number, String],
            default: 0
        },
        workflowTaskId: {
            type: Number,
            default: 0
        },
        taskStatus: {
            type: Number,
            default: 0
        },
        workflowId: {
            type: [String, Number],
            default: '0'
        },
        businessFormKey: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            data: {},
            planId: 0,
            customerInfo: [],
            formState: 1,
            reviewRecords: [],
            powerCapacityInfo: {
                electricSubstationAddr: '',
                electricAccessWay: '',
                transformerCapacitys: [],
                highVoltageMotors: [],
                customer: {},
                electricPlanMeteringEntities: [],
                voltage: ''
            },
            meteringInfo: {
                meterings: []
            },
            elecChargeInfo: {
                charges: []
            },
            importantLoadPowerInfo: {
                importantLoadPower: ''
            },
            businessFeeInfo: {
                isBusinessFee: '',
                businessFee: '',
                businessTotalFee: ''
            },
            designInfo: {
                designScopes: [],
                designPicNo: ''
            },
            investmentInterfaceInfo: {
                isInvestmentInterface: ''
            },
            middleCheckInfo: {
                isMiddleCheck: '',
                middleCheckItems: []
            },
            assetTransferInfo: {
                assetTransferScope: '',
                isAssetTransfer: ''
            },
            supplyDiagramFiles: [],
            hotLineWorkInfo: {
                isHotLineWork: '',
                plannedDate: ''
            },
            keyValues: {}
        };
    },
    methods: {
        loadData() {
            this.$client.getElectricPlanInfo(this.id).then((data) => {
                this.data = data;

                // 客户基本信息
                this.customerInfo.push({name: '户号(客户编号)', value: data.data.customerNumber});
                this.customerInfo.push({name: '工单号', value: data.data.businessNumber});
                this.customerInfo.push({name: '客户名称', value: data.data.customerName});
                this.customerInfo.push({name: '用电地址', value: data.data.useAddr});
                this.customerInfo.push({name: '经办人', value: data.data.agent});
                this.customerInfo.push({name: '联系电话', value: data.data.agentTel});

                this.planId = data.data.id;

                this.powerCapacityInfo.transformerCapacitys = JSON.parse(data.data.transformerCapacitys);
                this.powerCapacityInfo.electricSubstationAddr = data.data.electricSubstationAddr || '';
                this.powerCapacityInfo.electricAccessWay = data.data.electricAccessWay;
                this.powerCapacityInfo.highVoltageMotors = JSON.parse(data.data.highVoltageMotors || '[]');
                this.powerCapacityInfo.customer.totalCapacity = data.data.elecTotalCapacity;
                this.powerCapacityInfo.customer.updateCapacity = data.data.updateCapacity;
                this.powerCapacityInfo.customer.oldCapacity = data.data.oldCapacity;
                this.powerCapacityInfo.customer.elecNature = data.data.elecNature;
                this.powerCapacityInfo.voltage = data.data.voltage;

                this.powerCapacityInfo.electricPlanMeteringEntities = data.data.electricPlanMeterings.map(e => {
                    return {
                        powerAp: e.powerAp,
                        isInvestmentInterface: e.isInvestmentInterface,
                        supplyCapacity: e.supplyCapacity,
                        reconnaissancePlanId: e.reconnaissancePlanId
                    };
                });
                this.meteringInfo.meterings = data.data.electricPlanMeterings.map(e => {
                    return {
                        reconnaissancePlanId: e.reconnaissancePlanId,
                        meteringNo: e.meteringNo,
                        meteringType: e.meteringType,
                        meteringDeviceA: e.meteringDeviceA,
                        meteringDeviceV: e.meteringDeviceV,
                        meteringPos: e.meteringPos
                    };
                });

                this.elecChargeInfo.charges = data.data.electricPlanMeterings.map(e => {
                    return {
                        reconnaissancePlanId: e.reconnaissancePlanId,
                        businessName: e.businessName,
                        businessSupplyVoltage: e.businessSupplyVoltage,
                        chargeCollectType: e.chargeCollectType,
                        powerFactorStandard: e.powerFactorStandard,
                        isTimeDivision: e.isTimeDivision
                    };
                });

                this.importantLoadPowerInfo.importantLoadPower = data.data.importantLoadPower;

                this.businessFeeInfo = {
                    isBusinessFee: data.data.isBusinessFee,
                    businessFee: data.data.businessFee,
                    businessTotalFee: data.data.businessTotalFee,
                };

                this.designInfo = {
                    designScopes: (data.data.designScopes || []).map(e => parseInt(e)),
                    designPicNo: data.data.designPicNo,
                };

                this.investmentInterfaceInfo.isInvestmentInterface = data.data.isInvestmentInterface;

                this.middleCheckInfo = {
                    isMiddleCheck: data.data.isMiddleCheck,
                    middleCheckItems: (data.data.middleCheckItems || []).map(e => parseInt(e))
                };

                this.assetTransferInfo = {
                    assetTransferScope: data.data.assetTransferScope,
                    isAssetTransfer: data.data.isAssetTransfer
                };

                this.hotLineWorkInfo = {
                    isHotLineWork: data.data.isHotLineWork,
                    plannedDate: data.data.plannedDate
                };

                this.supplyDiagramFiles = data.data.supplyDiagramFiles;
            });

            this.$client.getReviewRecords({
                workflowId: this.workflowId,
                reviewFormKey: this.businessFormKey
            }).then((r) => {
                this.reviewRecords = r.data;
            });
        },
        cancel() {
            this.$confirm('确认取消？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('closePanel', 2);
            });
        },
        save() {
            this.formState = 0;
            this.submit();
        },
        validate(form) {
            return new Promise((resolve) => {
                if (!form) {
                    resolve(true);
                    return;
                }
                form.validate(valid => {
                    if (valid) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            });
        },
        async submit() {
            // todo 动态表单需要校验
            let forms = [
                this.$refs.form1,
                ...this.$refs.form2,
                ...this.$refs.form3,
                this.$refs.form4,
                this.$refs.form5,
                this.$refs.form6,
                this.$refs.form7,
                this.$refs.form8,
                this.$refs.form9,
                this.$refs.form10,
                this.$refs.form11
            ];

            for (let form of forms) {
                let valid = await this.validate(form.$refs.form);
                if (!valid) {
                    this.$message({
                        message: '请检查输入数据',
                        type: 'error'
                    });
                    return;
                }
            }
            if (!this.supplyDiagramFiles || this.supplyDiagramFiles.length === 0) {
                this.$message({
                    message: '请上传产权分界点示意图',
                    type: 'error'
                });
                return;
            }
            let confirm = await this.$confirm('确定保存', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            if (confirm !== 'confirm') {
                return;
            }
            this.loading = true;
            let electricPlanMeteringForms = [];
            for (let item of this.powerCapacityInfo.electricPlanMeteringEntities) {
                let charge = this.elecChargeInfo.charges.filter(e => e.reconnaissancePlanId === item.reconnaissancePlanId)[0];
                let metering = this.meteringInfo.meterings.filter(e => e.reconnaissancePlanId === item.reconnaissancePlanId)[0];
                electricPlanMeteringForms.push({
                    businessName: charge.businessName,
                    businessSupplyVoltage: charge.businessSupplyVoltage,
                    chargeCollectType: charge.chargeCollectType,
                    powerFactorStandard: charge.powerFactorStandard,
                    isInvestmentInterface: item.isInvestmentInterface,
                    isTimeDivision: charge.isTimeDivision,
                    meteringDeviceA: metering.meteringDeviceA,
                    meteringDeviceV: metering.meteringDeviceV,
                    meteringNo: metering.meteringNo,
                    meteringPos: metering.meteringPos,
                    meteringType: metering.meteringType,
                    powerAp: item.powerAp,
                    reconnaissancePlanId: item.reconnaissancePlanId,
                    supplyCapacity: item.supplyCapacity,
                });
            }
            this.$client.addElectricPlanInfo({
                agent: this.data.agent,
                agentTel: this.data.agentTel,
                applyId: this.id,
                businessNumber: this.data.businessNumber,
                customerName: this.data.customerName,
                customerNumber: this.data.customerNumber,
                elecTotalCapacity: this.data.elecTotalCapacity,
                ...this.assetTransferInfo,
                ...this.businessFeeInfo,
                ...this.designInfo,
                electricPlanMeterings: [...electricPlanMeteringForms],
                formState: this.formState,
                electricSubstationAddr: this.powerCapacityInfo.electricSubstationAddr,
                electricAccessWay: this.powerCapacityInfo.electricAccessWay,
                highVoltageMotors: JSON.stringify(this.powerCapacityInfo.highVoltageMotors),
                id: this.planId,
                workflowTaskId: this.workflowTaskId,
                ...this.importantLoadPowerInfo,
                ...this.hotLineWorkInfo,
                ...this.investmentInterfaceInfo,
                ...this.middleCheckInfo,
                oldCapacity: this.data.oldCapacity,
                supplyDiagramFiles: this.supplyDiagramFiles,
                ...this.powerCapacityInfo.customer,
                transformerCapacitys: JSON.stringify(this.powerCapacityInfo.transformerCapacitys),
                useAddr: this.data.useAddr,
                voltage: this.data.voltage
            }).then((data) => {
                this.$message({
                    message: '提交成功',
                    type: 'success'
                });
                this.$emit('closePanel', 2);
                this.loading = false;
            });
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        }
    },
    created() {
        this.loadData();
    }
};
</script>
