<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item prop="isMiddleCheck" label="是否免检">
                        <el-radio v-model="formData.isMiddleCheck" v-for="i in keyValues.isMiddleCheck" :label="i.itemKey" :key="i.itemKey">{{i.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="formData.isMiddleCheck===0">
                    <el-form-item prop="middleCheckItems" label="免检选项">
                        <el-checkbox-group v-model="formData.middleCheckItems">
                            <el-checkbox
                                v-for="item in keyValues.middleCheckItems"
                                :label="parseInt(item.itemKey)"
                                :key="parseInt(item.itemKey)"
                                >{{ item.itemValue }}</el-checkbox
                            >
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'MiddleCheckInfo',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    isMiddleCheck: '',
                    middleCheckItems: []
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                isMiddleCheck: [
                    {
                        required: true,
                        message: '是否免检 ' + '必填',
                        trigger: 'change'
                    }
                ]
            },
            keyValues: {
                isMiddleCheck: [
                    {itemKey: 0, itemValue: '免检查'},
                    {itemKey: 1, itemValue: '须检查(隐蔽工程覆盖之前须提交中间检查申请)'}
                ],
                middleCheckItems: [],
            }
        };
    },
    methods: {
        async bindKeyValues() {
            const data = await Promise.all([
                this.$client.getDicData('middle_check_item'),
            ]);
            this.keyValues.middleCheckItems = data[0].data || [];
        },
    },
    created() {
        this.bindKeyValues();
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>


