<template>
    <div class="info" v-if="info">
        <h4 style="text-align: center;color: #235eff;">施工审核</h4>
        <table border="1" cellspacing="0" cellpadding="0">
            <tbody>
                <tr>
                    <td>项目承接单位：{{ info.projectUnit }}</td>
                    <td>任务类别：{{ info.flawTaskType }}</td>
                    <td colspan="2">编号：{{ info.id }}</td>
                </tr>
                <tr>
                    <td>委托单位：滇中</td>
                    <td>受委托方：{{ info.trusteeUnit }}</td>
                    <td>委托时间：{{ info.createTime }}</td>
                    <td>电压等级（KV）：{{ info.voltageName }}</td>
                </tr>
                <tr>
                    <td>任务通知人：{{ info.noticeUser }}</td>
                    <td>运行专责：{{ info.runOwner }}</td>
                    <td>被通知人：{{ info.noticeUser }}</td>
                    <td>负责人：{{ info.maintenanceOwner }}</td>
                </tr>
                <tr>
                    <td colspan="4">工作内容：{{ info.workContent }}</td>
                </tr>
                <tr>
                    <td colspan="2">工作地点：{{ info.workAddr }}</td>
                    <td colspan="2">工作区域：{{ info.workArea }}</td>
                </tr>
                <tr>
                    <td colspan="2">到场时间：{{ info.localeTime }}</td>
                    <td colspan="2">完成时间：{{ info.finishTime }}</td>
                </tr>
                <tr>
                    <td colspan="4">工作量：{{ info.workload }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    props: {
    },
    data() {
        return {
            info: {
                id: 0,
                workflowTaskId: 0,
                equipmentFlawId: '',
                flawTaskType: '',
                projectUnit: '',
                projectUnitId: '',
                trusteeUnit: '',
                trusteeUnitId: '',
                voltage: '',
                voltageName: '',
                runOwner: '',
                runOwnerId: '',
                dispatchOwner: '',
                dispatchOwnerId: '',
                maintenanceOwner: '',
                maintenanceOwnerId: '',
                noticeUser: '',
                noticeUserId: '',
                supervisor: '',
                workContent: '',
                workAddr: '',
                workArea: '',
                localeTime: '',
                finishTime: '',
                workload: ''
            }
        };
    },
    methods: {
        async init(applyId) {
            let res = await this.$client.getFlawTaskDetail(applyId);
            Object.assign(this.info, res.data);
            this.$client.getFlawTaskAcceptDetail(applyId).then(res => {
                this.info.localeTime = res.data.localeTime;
                this.info.finishTime = res.data.finishTime;
                this.info.workload = res.data.workload;
            });
        }
    }
};
</script>
<style lang="less">
.info {
    table {
        width: 100%;
        td{
            padding: 2px 10px;
        }
    }
    padding: 20px 10px;
}
</style>
