<template>
<!-- 接入方式 -->
<div>
    <div v-for="(item, index) in accessPlanList" :key="index">
        <span>{{item.planName}}</span>
        <div>{{item.powerPointDescription}}</div>
    </div>
</div>
</template>

<script>
export default {
    name: 'AccessMethod',
    props: {
        accessPlanList: [],
        formData: {},
    },
    data() {
        return {
            loading: false,
            formRules: {}
        };
    },
    computed: {},
    methods: {
        // async initFormValue() {
        //     await this.bindKeyValues();
        // },
    },
    created() {
        // this.initFormValue();
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>
