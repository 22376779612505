<template>
    <div>
    <el-form :model="formData" :rules="formRules" ref="form" size="mini">
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item prop="customerName" :label="'客户名称'">
                        <el-input v-model="formData.customerName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item prop="useLongitude" :label="'用电地址经纬度坐标'">
                        <el-input :value="useLocation" @focus="showMap"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item prop="useAddr" :label="'用电地址'">
                        <el-input v-model="formData.useAddr"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="'通邮地址'" prop="contactAddr">
                        <el-input v-model="formData.contactAddr"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'邮政编码'" prop="zipCode">
                        <el-input v-model="formData.zipCode"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'传真'" prop="faxes">
                        <el-input v-model="formData.faxes"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="'客户身份'" prop="customerIdentity">
                        <el-radio
                            v-model="formData.customerIdentity"
                            v-for="item in keyValues.customerIdentity"
                            :label="parseInt(item.itemKey)"
                            :key="parseInt(item.itemKey)"
                            >{{ item.itemValue }}</el-radio
                        >
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'客户类型'" prop="customerType">
                        <el-radio
                            v-model="formData.customerType"
                            v-for="item in keyValues.customerType"
                            :label="parseInt(item.itemKey)"
                            :key="parseInt(item.itemKey)"
                            >{{ item.itemValue }}</el-radio
                        >
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'客户类别'" prop="customerIndustry">
                        <el-checkbox-group v-model="formData.customerIndustry">
                            <el-checkbox
                                v-for="item in keyValues.customerIndustry"
                                :label="parseInt(item.itemKey)"
                                :key="parseInt(item.itemKey)"
                                >{{ item.itemValue }}</el-checkbox
                            >
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="'业务经办人'" prop="agent">
                        <el-input v-model="formData.agent"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'身份证号码'" prop="agentNo">
                        <el-input v-model="formData.agentNo"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'联系电话'" prop="agentTel">
                        <el-input v-model="formData.agentTel"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="'证件类型'" prop="idType">
                        <el-select v-model="formData.idType">
                          <el-option v-for="item in keyValues.idType"
                                     :key="parseInt(item.itemKey)"
                                     :value="parseInt(item.itemKey)"
                                     :label="item.itemValue"></el-option>
                      </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'证件号码'" prop="idNumber">
                        <el-input v-model="formData.idNumber"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'用电联系人'" prop="useRelName">
                        <el-input v-model="formData.useRelName"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="'用电联系电话'" prop="useRelTel">
                        <el-input  v-model="formData.useRelTel"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'电费联系人'" prop="chargeRelName">
                        <el-input v-model="formData.chargeRelName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'电费联系电话'" prop="chargeRelTel">
                        <el-input v-model="formData.chargeRelTel"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="'电子账单邮箱地址'" prop="billEmail">
                        <el-input v-model="formData.billEmail"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'手机短信号码'" prop="smsTel">
                        <el-input v-model="formData.smsTel"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'电费担保方式(租赁户)'" prop="elecAssureType">
                        <el-radio-group v-model="formData.elecAssureType">
                            <el-radio
                                v-for="item in keyValues.elecAssureType"
                                :key="parseInt(item.itemKey)"
                                :label="parseInt(item.itemKey)"
                                >{{ item.itemValue }}</el-radio
                            >
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
    </el-form>

    <el-dialog v-bind="mapDialog" v-if="mapDialog.visible" :visible.sync="mapDialog.visible"
               :close-on-click-modal="false" :append-to-body="true">
        <div style="height: 500px;">
            <map-base
                ref="mapbase"
                @mapLayerClick="handleMapLayerClick"
                :lines="[]"
                :points="points"
                :emitAddress="true"
                :searchAddress="true"
            >
            </map-base>
        </div>

        <span slot="footer">
        <el-button @click="mapDialog.visible = false" >确定</el-button>
        </span>
    </el-dialog>
    </div>
</template>
<script>
import MapBase from '@/pages/powerGridMonitor/MapBase.vue';
import mapCommon from '@/pages/powerGridMonitor/mapCommon';

export default {
    name: 'CustomerInfo',
    mixins: [mapCommon],
    components: {
        MapBase,
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    customerName: '',
                    useLongitude: '',
                    useLatitude: '',
                    useAddr: '',
                    contactAddr: '',
                    zipCode: '',
                    faxes: '',
                    customerIdentity: '',
                    customerType: '',
                    customerIndustry: [],
                    agent: '',
                    agentNo: '',
                    agentTel: '',
                    idType: '',
                    idNumber: '',
                    useRelName: '',
                    useRelTel: '',
                    chargeRelName: '',
                    chargeRelTel: '',
                    billEmail: '',
                    electronicBill: 0,
                    sms: 0,
                    smsTel: '',
                    elecAssureType: ''
                };
            },
        },
    },
    watch: {
        formData: {
            handler(newVal) {
                if (newVal.billEmail != null && newVal.billEmail !== '') {
                    newVal.electronicBill = 1;
                }
                if (newVal.smsTel != null && newVal.smsTel !== '') {
                    newVal.sms = 1;
                }
            },
            deep: true
        }
    },
    data() {
        return {
            loading: false,
            points: [],
            formRules: {
                customerName: [
                    {
                        required: true,
                        message: '客户名称' + ' ' + '必填',
                        trigger: 'change',
                    },
                ],
                useLongitude: [
                    {
                        required: true,
                        message: '用电地址经纬度坐标' + ' ' + '必选',
                        trigger: 'change',
                    },
                ],
                useAddr: [
                    {
                        required: true,
                        message: '用电地址' + ' ' + '必填',
                        trigger: 'change',
                    },
                ],
                customerIdentity: [
                    {
                        required: true,
                        message: '客户身份' + ' ' + '必填',
                        trigger: 'change',
                    },
                ],
                customerType: [
                    {
                        required: true,
                        message: '客户类型' + ' ' + '必填',
                        trigger: 'change',
                    },
                ],
                customerIndustry: [
                    {
                        required: true,
                        message: '客户类别' + ' ' + '必填',
                        trigger: 'change',
                    },
                ],
                agent: [
                    {
                        required: true,
                        message: '业务经办人' + ' ' + '必填',
                        trigger: 'change',
                    },
                ],
                agentNo: [
                    {
                        required: true,
                        message: '身份证号码' + ' ' + '必填',
                        trigger: 'change',
                    },
                ],
                agentTel: [
                    {
                        required: true,
                        message: '联系电话' + ' ' + '必填',
                        trigger: 'change',
                    },
                ],
                idType: [
                    {
                        required: true,
                        message: '证件类型' + ' ' + '必填',
                        trigger: 'change',
                    },
                ],
                idNumber: [
                    {
                        required: true,
                        message: '证件号码' + ' ' + '必填',
                        trigger: 'change',
                    },
                ],
            },
            keyValues: {
                customerIdentity: [],
                customerIndustry: [],
                customerType: [],
                elecAssureType: [],
                idType: []
            },
            mapDialog: {
                title: '选择用电地址',
                visible: false,
                width: '900px',
            },
        };
    },
    computed: {
        useLocation() {
            if (this.formData.useLongitude && this.formData.useLatitude) {
                return this.formData.useLongitude + ',' + this.formData.useLatitude;
            }
            return '';
        }
    },
    methods: {
        async bindKeyValues() {
            const dictMap = [
                {parentCode: 'customer_identity', listName: 'customerIdentity'},
                {parentCode: 'customer_industry', listName: 'customerIndustry'},
                {parentCode: 'customer_type', listName: 'customerType'},
                {parentCode: 'elec_assure_type', listName: 'elecAssureType'},
                {parentCode: 'id_type', listName: 'idType'},
            ];
            this.$client.getDicDataArr(dictMap.map((it) => it.parentCode)).then((res) => {
                let {data: resData} = res;
                for (let key in resData) {
                    let data = resData[key];
                    let dict = dictMap.filter((it) => it.parentCode === key)[0];
                    this.keyValues[dict.listName] = data;
                }
            });

            // const data = await Promise.all([
            //     this.$client.getDicData('customer_identity'),
            //     this.$client.getDicData('customer_industry'),
            //     this.$client.getDicData('customer_type'),
            //     this.$client.getDicData('elec_assure_type'),
            //     this.$client.getDicData('id_type'),
            // ]);
            // this.keyValues.customerIdentity = data[0].data || [];
            // this.keyValues.customerIndustry = data[1].data || [];
            // this.keyValues.customerType = data[2].data || [];
            // this.keyValues.elecAssureType = data[3].data || [];
            // this.keyValues.idType = data[4].data || [];
        },
        async initFormValue() {
            await this.bindKeyValues();
            this.fo;
        },
        showMap() {
            this.mapDialog.visible = true;
        },
        handleMapLayerClick(data) {
            let {longitude, latitude} = data;
            let point = [{
                coords: [longitude, latitude],
                categoryName: '选中位置',
                longitude,
                latitude
            }];
            this.points = point;
            this.formData.useLongitude = longitude;
            this.formData.useLatitude = latitude;
            this.formData.useAddr = data.address;
            this.$nextTick(() => {
                this.$refs.mapbase.updateAll();
            });
        }
    },
    created() {
        this.initFormValue();
    },
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}

/deep/ .chart-map {
    height: 100%;
}

</style>


