<template>
    <div>
        <equipment-flaw-info  v-if="showEquipmentFlawInfo" :info="equipmentFlaw" ref="equipmentFlawInfo"></equipment-flaw-info>
        <flaw-task-construction-info ref="ref_FlawTaskConstructionInfo"></flaw-task-construction-info>

        <div class="info2" v-if="info">
            <div style="display: flex;align-content: center;">
            <h5 style="width: 90%;">验收申请</h5>
                <el-link  @click="downloadFile">下载</el-link>
            </div>
            <table border="1" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr style="display: none;">
                        <th style="width:12.5%;"></th>
                        <th style="width:12.5%;"></th>
                        <th style="width:12.5%;"></th>
                        <th style="width:12.5%;"></th>
                        <th style="width:12.5%;"></th>
                        <th style="width:12.5%;"></th>
                        <th style="width:12.5%;"></th>
                        <th style="width:12.5%;"></th>
                    </tr>
                    <tr>
                        <td>工程名称</td>
                        <td colspan="7">{{ info.projectName }}</td>
                    </tr>
                    <tr>
                        <td>施工单位</td>
                        <td colspan="7">{{ info.constructionUnit }}</td>
                    </tr>

                    <tr>
                        <td>开工日期</td>
                        <td colspan="3">{{ info.startWorkDate }}</td>
                        <td>竣工日期</td>
                        <td colspan="3">{{ info.endWorkDate }}</td>
                    </tr>
                    <tr>
                        <td>工程主要内容及工程量</td>
                        <td colspan="7">{{ info.workContent }}{{ info.workload }}</td>
                    </tr>
                    <tr>
                        <td rowspan="3">验收情况</td>
                        <td colspan="7">
                            <ul class="el-upload-list el-upload-list--text" style="margin-left: 20px;">
                                <li v-for="(item, index) in info.checkImgs" tabindex="0" class="el-upload-list__item is-success" :key="index">
                                    <a class="el-upload-list__item-name" :href="item.url"><i class="el-icon-document"></i>{{item.name}}</a>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="7">参加验收人员：{{ info.checkUser }}</td>
                    </tr>
                    <tr>
                        <td colspan="7">验收时间：{{ info.checkDate }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">项目施工单位意见：{{ info.constructionUnitAdvise }}</td>
                        <td colspan="2">监理单位意见：{{ info.checkUnitAdvise }}</td>
                        <td colspan="2">设计单位意见：{{ info.designUnitAdvise }}</td>
                        <td colspan="2">项目责任单位意见：{{ info.projectUnitAdvise }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">签字：<img :src="info.constructionUnitSignatureUrl.url" /></td>
                        <td colspan="2">签字：<img :src="info.checkUnitSignatureUrl.url" /></td>
                        <td colspan="2">签字：<img :src="info.designUnitSignatureUrl.url" /></td>
                        <td colspan="2">签字：<img :src="info.projectUnitSignatureUrl.url" /></td>
                    </tr>
                    <tr>
                        <td colspan="2">签字时间：{{ info.createTime }}</td>
                        <td colspan="2">签字时间：{{ info.createTime }}</td>
                        <td colspan="2">签字时间：{{ info.createTime }}</td>
                        <td colspan="2">签字时间：{{ info.createTime }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import FlawTaskConstructionInfo from '@/pages/emergencyRepair/flawTaskConstructionInfo';
import EquipmentFlawInfo from '@/pages/emergencyRepair/equipmentFlawInfo';
import {previewAndDownImage} from '@/utils';
export default {
    components: {
        FlawTaskConstructionInfo, EquipmentFlawInfo
    },
    props: {},
    data() {
        return {
            showEquipmentFlawInfo: false,
            equipmentFlaw: null,
            info: null,
            businessFormId: 0
        };
    },
    methods: {
        previewImage(item) {
            previewAndDownImage('验收情况', item.url);
        },
        init(row, workflowId) {
            this.businessFormId = row.businessFormId;
            if (row.currentStatus === '0' || row.currentStatus === '10') {
                this.showEquipmentFlawInfo = true;
                this.$client.getEquipmentFlawDetail(row.businessFormId).then(res => {
                    this.equipmentFlaw = res.data;
                });
            } else {
                this.$client.getFlawTaskCompletionDetail(row.businessFormId).then(res => {
                    this.info = res.data;
                    this.info.checkImgs = this.handleData(this.info.checkImgs);
                    this.$set(this.info, 'constructionUnitSignatureUrl', this.handleData(this.info.constructionUnitSignature)[0]);
                    this.$set(this.info, 'checkUnitSignatureUrl', this.handleData(this.info.checkUnitSignature)[0]);
                    this.$set(this.info, 'designUnitSignatureUrl', this.handleData(this.info.designUnitSignature)[0]);
                    this.$set(this.info, 'projectUnitSignatureUrl', this.handleData(this.info.projectUnitSignature)[0]);
                });
                this.$refs.ref_FlawTaskConstructionInfo.init(workflowId, row.businessFormId);
            }
        },
        downloadFile() {
            location.href = this.axios.defaults.baseURL + '/business/flawTaskCompletions/download/' + this.businessFormId + '?token=' + this.$store.state.token;
        },
        handleData(fileList) {
            //统一数据格式化
            return fileList.map(item => {
                return {...item, url: this.axios.defaults.baseURL + '/files/' + item.id + '?token=' + this.$store.state.token};
            });
        }
    }
};
</script>
<style lang="less">
.info2 {
    table {
        width: 100%;
        td {
            padding: 2px 10px;
        }
    }
    padding: 0 10px 20px;
    img {
        height: 100px;
        width: auto;
    }
}
</style>
