<template>
    <div>
        <electric-plan-review
            ref="ref_electricPlanInfo"
            :id="data.businessFormId"
            :workflowId="data.workflowId"
            businessFormKey="form_electric_plan"
            :workflowTaskId="data.id"
            @closePanel="closeForm"
            v-if="showElectricPlanInfo"
            @clickStep="clickStep"
        ></electric-plan-review>
        <flow-drawing-apply-info v-if="showFlowDrawingApplyInfo" ref="ref_flowDrawingApplyInfo" @closePanel="closeForm"></flow-drawing-apply-info>
        <flow-overhaul-approve v-if="showOverhaul" ref="ref_overhaul" @closePanel="closeForm"></flow-overhaul-approve>
        <flaw-task-accept-info v-if="showFlawTaskAcceptInfo" ref="ref_FlawTaskAcceptInfo" @closeForm="closeForm"></flaw-task-accept-info>
        <flaw-task-completion-info v-if="showFlawTaskCompletionInfo" ref="ref_FlawTaskCompletionInfo" @closeForm="closeForm"></flaw-task-completion-info>
        <flow-put-into-production-approve v-if="showPutIntoProduction" ref="ref_put_into_production" @closePanel="closeForm"></flow-put-into-production-approve>
        <!--通用审核组件,默认不显示-->
        <div v-if="showReviewForm">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>审核情况</span>
                </div>
                <el-timeline>
                    <el-timeline-item v-for="(item, index) in reviewList" :key="index" :timestamp="item.reviewTime" placement="top">
                        <el-card>
                            <h4>{{ item.reviewNote }}</h4>
                            <p>{{ item.reviewBy }} 提交于 {{ item.reviewTime }}</p>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
                <el-form
                    label-position="top"
                    :model="reviewForm"
                    ref="reviewForm"
                    @keyup.enter.native="reviewFormSubmit()"
                    label-width="120px"
                >
                    <el-form-item label="审核意见" prop="reviewNote">
                        <el-input
                            type="textarea"
                            v-model="reviewForm.reviewNote"
                            placeholder="审核意见"
                            :row="5"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </el-card>
            <div class="submit-btn-group">
                <el-button @click="closeForm">关闭</el-button>
                <el-button type="danger" @click="rejectSubmit">驳回</el-button>
                <el-button type="primary" @click="reviewFormSubmit(1)">通过</el-button>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * 通用审核组件
 */
import ElectricPlanReview from '@/pages/electricPlan/electricPlanReview';
import FlowDrawingApplyInfo from '@/pages/drawing/info';
import FlowOverhaulApprove from '@/pages/overhaul/overhaulDetail';
import FlawTaskAcceptInfo from '@/pages/emergencyRepair/flawTaskAcceptInfo';
import FlawTaskCompletionInfo from '@/pages/emergencyRepair/flawTaskCompletionInfo';
import FlowPutIntoProductionApprove from '@/pages/putIntoProduction/putIntoProductionDetail';
export default {
    components: {
        ElectricPlanReview,
        FlowDrawingApplyInfo,
        FlowOverhaulApprove,
        FlawTaskAcceptInfo,
        FlawTaskCompletionInfo,
        FlowPutIntoProductionApprove,
    },
    data() {
        return {
            showFlowDrawingApplyInfo: false,
            showElectricPlanInfo: false,
            showFlawTaskAcceptInfo: false,
            showFlawTaskCompletionInfo: false,
            showReviewForm: false,
            showOverhaul: false,
            showPutIntoProduction: false,
            workflowTaskId: 0,
            reviewList: [],
            reviewForm: {
                reviewNote: '通过',
                reviewStatus: 0,
                reviewParam: 0
            },
            showRejectBox: false,
            rejectCallback: null,
            data: {}
        };
    },
    methods: {
        init(item) {
            this.workflowTaskId = item.id;
            switch (item.processDefinitionKey) {
                case 'flow_business_apply':
                    this.revireBusinessApply(item);
                    break;
                case 'flow_drawing_apply':
                    this.showFlowDrawingApplyInfo = true;
                    this.showReviewForm = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flowDrawingApplyInfo.init(item);
                        this.getReviewList(item.workflowId, item.businessFormKey);
                    });
                    break;
                case 'flow_emergency_repair':
                    this.showReviewForm = true;
                    this.getReviewList(item.workflowId, item.reviewFormKey);
                    if (item.reviewFormKey === 'form_flaw_task_accept') {
                        this.showFlawTaskAcceptInfo = true;
                        this.showRejectBox = true;
                        this.rejectCallback = function() {
                            this.$confirm('请选择需要驳回到哪一步?', '提示', {
                                confirmButtonText: '填写工作接受单',
                                cancelButtonText: '填写工作单',
                                type: 'warning'
                            }).then(() => {
                                this.reviewForm.reviewParam = 2;
                                this.reviewFormSubmit(0);
                            }).catch(() => {
                                this.reviewForm.reviewParam = 1;
                                this.reviewFormSubmit(0);
                            });
                        };
                        this.$nextTick(() => {
                            this.$refs.ref_FlawTaskAcceptInfo.init(item.businessFormId);
                        });
                    } else {
                        this.showFlawTaskCompletionInfo = true;
                        this.$nextTick(() => {
                            this.$refs.ref_FlawTaskCompletionInfo.init(item, item.workflowId);
                        });
                    }
                    break;
                case 'flow_overhaul':
                case 'flow_overhaul_1':
                    this.showOverhaul = true;
                    this.showReviewForm = true;
                    this.$nextTick(() => {
                        this.$refs.ref_overhaul.init(item);
                        this.getReviewList(item.workflowId, item.businessFormKey);
                    });
                    break;
                case 'flow_put_into_production':
                    this.showPutIntoProduction = true;
                    this.showReviewForm = true;
                    this.$nextTick(() => {
                        this.$refs.ref_put_into_production.init(item);
                        this.getReviewList(item.workflowId, item.businessFormKey);
                    });
                    break;
                default:
                    break;
            }
        },
        revireBusinessApply(item) {
            if (item.currentStatus === '22' || item.currentStatus === '23') {
                //供电方案审核,展示供电方案数据
                this.data = item;
                this.showElectricPlanInfo = true;
                // this.$nextTick(() => {

                //     // this.$refs.ref_electricPlanInfo.init(item);
                // });
                // this.getReviewList(item.workflowId, 'form_electric_plan');
            }
        },
        getReviewList(workflowId, reviewFormKey) {
            this.$client.getReviewRecords({workflowId, reviewFormKey}).then(res => {
                this.reviewList = res.data;
            });
        },
        rejectSubmit() {
            if (this.showRejectBox) {
                this.rejectCallback.call(this);
                return;
            }
            this.reviewFormSubmit(0);
        },
        reviewFormSubmit(reviewStatus) {
            this.reviewForm.reviewStatus = reviewStatus;
            this.$client.completeReviewTask(this.workflowTaskId, this.reviewForm).then(() => {
                this.$message({
                    message: '操作成功',
                    type: 'success'
                });
                this.closeForm(2);
            });
        },
        closeForm(type) {
            this.$emit('closeForm', type);
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        }
    }
};
</script>
