<template>
    <div>
        <p style="font-weight: bold;">
            图纸名称：{{ dataForm.drawingName }} 工作单号：{{ dataForm.businessNumber }} 图纸数量：{{
                dataForm.drawingNum
            }}
        </p>
        <el-tabs v-model="activeName">
            <el-tab-pane label="单线图" name="1">
                <p style="font-weight: bold;">单线图：{{ drawing10.length }}</p>
                <el-form :inline="true">
                    <el-form-item v-for="(item) in drawing10" :key="item.ossId">
                        <div style="position: relative;">
                            <el-image
                                @click="handlePictureCardPreview(item)"
                                style="width: 300px; height: 200px"
                                :src="baseServer + item.ossId + '/thumbnail?token=' + $store.state.token"
                                fit="contain"
                            ></el-image>
                        </div>
                        <div style="display: flex;flex-direction: column;">
                            <span>图纸名称：{{ item.drawingName }}</span>
                            <span>图纸编号：{{ item.drawingCode }}</span>
                        </div>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="环网柜主线图" name="2">
                <p style="font-weight: bold;">环网柜主线图：{{ drawing20.length }}</p>
                <el-form :inline="true">
                    <el-form-item v-for="(item, index) in drawing20" :key="index">
                        <div style="position: relative;">
                            <el-image
                                @click="handlePictureCardPreview(item)"
                                style="width: 300px; height: 200px"
                                :src="baseServer + item.ossId + '/thumbnail?token=' + $store.state.token"
                                fit="contain"
                            ></el-image>
                        </div>
                        <div style="display: flex;flex-direction: column;">
                            <span>图纸名称：{{ item.drawingName }}</span>
                            <span>图纸编号：{{ item.drawingCode }}</span>
                        </div>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="箱式变电站主线图" name="3">
                <p style="font-weight: bold;">箱式变电站主线图：{{ drawing30.length }}</p>
                <el-form :inline="true">
                    <el-form-item v-for="(item) in drawing30" :key="item.ossId">
                        <div style="position: relative;">
                            <el-image
                                @click="handlePictureCardPreview(item)"
                                style="width: 300px; height: 200px"
                                :src="baseServer + item.ossId + '/thumbnail?token=' + $store.state.token"
                                fit="contain"
                            ></el-image>
                        </div>
                        <div style="display: flex;flex-direction: column;">
                            <span>图纸名称：{{ item.drawingName }}</span>
                            <span>图纸编号：{{ item.drawingCode }}</span>
                        </div>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
import {previewPdf} from '@/utils';
export default {
    data() {
        return {
            activeName: '1',
            baseServer: this.axios.defaults.baseURL + '/files/',
            dataForm: {
                id: 0,
                businessNumber: '',
                drawingName: '',
                drawingNum: '',
                drawingStatus: '',
                note: ''
            },
            drawing10: [],
            drawing20: [],
            drawing30: [],
            dialogImageUrl: '',
            dialogVisible: false
        };
    },
    methods: {
        init(row) {
            this.dataForm.id = row.businessFormId || 0;
            this.$nextTick(() => {
                if (this.dataForm.id) {
                    this.$client.getDrawingApplyInfo(this.dataForm.id).then(data => {
                        this.dataForm = data.drawingApply;
                        this.dataForm.workflowId = row.id;
                    });
                    this.$client.getDrawingList({applyId: this.dataForm.id}).then(data => {
                        this.drawing10 = data.drawing.drawing10;
                        this.drawing20 = data.drawing.drawing20;
                        this.drawing30 = data.drawing.drawing30;
                    });
                }
            });
        },
        handlePictureCardPreview(item) {
            previewPdf(
                this.baseServer + item.ossId + '?token=' + this.$store.state.token,
                item.drawingName + '  ' + item.drawingCode
            );
        }
    }
};
</script>
