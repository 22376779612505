import { render, staticRenderFns } from "./importantLoadPowerInfo.vue?vue&type=template&id=7c989a90&scoped=true&"
import script from "./importantLoadPowerInfo.vue?vue&type=script&lang=js&"
export * from "./importantLoadPowerInfo.vue?vue&type=script&lang=js&"
import style0 from "./importantLoadPowerInfo.vue?vue&type=style&index=0&id=7c989a90&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c989a90",
  null
  
)

export default component.exports