<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
            <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="24">
                    <span style="background-color:red">计量点{{index+1}}</span>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item prop="meteringNo" label="计量点号">
                        <el-input v-model="formData.meteringNo"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="meteringType" label="计量方式采用">
                        <el-radio v-model="formData.meteringType" v-for="i in keyValues.meteringType" :label="parseInt(i.itemKey)" :key="i.itemKey">{{i.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="6">
                    <el-form-item prop="meteringDeviceA" label="电流互感器变比为">
                        <el-input v-model="formData.meteringDeviceA"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item prop="meteringDeviceV" label="电压互感器变比为">
                        <el-input v-model="formData.meteringDeviceV"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="meteringPos" label="计量点位置">
                        <el-input v-model="formData.meteringPos"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            </div>
        </el-form>
</template>
<script>
export default {
    name: 'MeteringInfo',
    components: {
    },
    props: {
        index: {
            default: 0
        },
        formData: {
            type: Object,
            default: () => {
                return {
                    meteringNo: '',
                    meteringType: '',
                    meteringDeviceA: '',
                    meteringDeviceV: '',
                    meteringPos: ''
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                /*meteringNo: [
                    {
                        required: true,
                        message: '计量点号 ' + '必填',
                        trigger: 'change'
                    }
                ],*/
                meteringType: [
                    {
                        required: true,
                        message: '计量方式采用 ' + '必填',
                        trigger: 'change'
                    }
                ],
                meteringDeviceA: [
                    {
                        required: true,
                        message: '电流互感器变比为 ' + '必填',
                        trigger: 'change'
                    }
                ],
                meteringDeviceV: [
                    {
                        required: true,
                        message: '电压互感器变比为 ' + '必填',
                        trigger: 'change'
                    }
                ],
                meteringPos: [
                    {
                        required: true,
                        message: '计量点位置 ' + '必填',
                        trigger: 'change'
                    }
                ],
            },
            keyValues: {
                meteringType: [],
            }
        };
    },
    methods: {
        async bindKeyValues() {
            const data = await Promise.all([
                this.$client.getDicData('metering_type'),
            ]);
            this.keyValues.meteringType = data[0].data || [];
        },
    },
    created() {
        this.bindKeyValues();
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>


