<template>
    <div>
        <step-bar :active="0" @clickStep="clickStep"></step-bar>
        <el-card class="card-pub-notice card-ext-center-title" v-if="step===1">
            <div slot="header" class="notice_title">
                <span>{{$t('businessApply.form.section1')}}</span>
            </div>
            <div class="commonNotice">

                <!-- 用电方信息 -->
                <ex-card :headerName="$t('businessApply.form.customerInfo')">
                <div class="commonNotice">
                <customer-info ref="form1" :formData="customerInfo" class="commonNotice"></customer-info>
                </div>
                </ex-card>

                <!-- 业务内容 -->
                <ex-card :headerName="$t('businessApply.form.businessContent')">
                <div class="commonNotice">
                <business-content ref="form2" :formData="businessContent" class="commonNotice"></business-content>
                </div>
                </ex-card>

                <!-- 电费结算 -->
                <ex-card :headerName="$t('businessApply.form.elecBill')">
                <div class="commonNotice">
                <elec-bill ref="form3" :formData="elecBill" class="commonNotice"></elec-bill>
                </div>
                </ex-card>


                <!-- 增值税开票资料 -->
                <ex-card :headerName="$t('businessApply.form.invoiceInfo')">
                <div class="commonNotice">
                <invoice-info ref="form4" :formData="invoiceInfo" class="commonNotice"></invoice-info>
                </div>
                </ex-card>

                <!-- 申请说明 -->
                <ex-card :headerName="$t('businessApply.form.declarationNote')" style="display: none;">
                <div class="commonNotice">
                <declaration-note ref="form5" :formData="declarationNote" class="commonNotice"></declaration-note>
                </div>
                </ex-card>

                <!-- 客户说明 -->
                <ex-card :headerName="$t('businessApply.form.customerNote')">
                <div class="commonNotice">
                <div class="ulbox">
                            <el-row :gutter="24">
                                <el-col :span="24">
                                    <span><span style="color: #F56C6C;margin-right: 5px;">*</span>{{$t('businessApply.form.customerNoteContent')}}</span>
                                    <!--<img :src="signImg1" style="width: 300px;height: auto" />
                                    <el-button @click="openSignDlg(1)" type="primary">{{$t('businessApply.form.clickToSignDlg')}}</el-button>-->
                                </el-col>
                            </el-row>
                        </div>
                </div>
                </ex-card>

            </div>
        </el-card>

        <el-card class="card-pub-notice card-ext-center-title" v-if="step===1">
            <div slot="header" class="notice_title">
                <span>{{$t('businessApply.form.equipmentInfoList')}}</span>
            </div>
            <div class="commonNotice">
                <div class="ulbox" style="font-size: 13px">
                    <el-card v-for="(item,index) in equipmentList" :key="index" class="card-pub-notice">
                        <div class="commonNotice">
                            <div class="el-card__subheader">
                                <div class="clearfix">
                                    <span>{{$t('businessApply.form.equipment')+(index+1)}}</span>
                                    <i class="el-icon-close subheader_close ui-link" style="margin-left: 10px;" @click="removeEquipment(index)"></i>
                                    <div class="triangle_border_up">
                                        <span></span>
                                    </div>
                                </div>
                            </div>

                            <equipment-info ref="form6" :formData="item"></equipment-info>
                        </div>
                    </el-card>

                    <el-row :gutter="24">
                        <el-col :span="5">{{$t('businessApply.form.total1')}}:
                            <el-input v-model="total1" style="width: 200px;"></el-input>
                        </el-col>
                        <el-col :span="8">{{$t('businessApply.form.total2')}}:
                            <el-input v-model="equipmentTotalCapacity" style="width: 200px;"></el-input>
                        </el-col>
                        <el-col :span="4">
                            <el-button @click="addEquipment" icon="el-icon-plus" type="primary">{{$t('businessApply.form.addEquipment')}}</el-button>
                        </el-col>
                    </el-row>

                    <div style="margin-top:50px;">
                        <span style="font-weight: bold;"><span style="color: #F56C6C;margin-right: 5px;">*</span>说明：</span>
                        <p>1. 客户办理用电申请报装手续时，如有10kV电机或电能质量污染源设备，应在本清单内清楚填写。</p>
                        <p>2. 电能质量污染源设备包括：地铁及城铁整流器、电力机车、有轨、无轨电车、电解槽、逆变电源、直流电弧炉、单晶炉、中频炉、变频电机、变频水泵、 大型电梯、交流电弧炉、电焊机、轧机、电铲、绞车等。
                        </p>
                    </div>
                   <!-- <img :src="signImg2" style="width: 300px;height: auto" />
                    <el-button type="primary" @click="openSignDlg(2)">{{$t('businessApply.form.clickToSignDlg')}}</el-button>-->

                </div>
            </div>
        </el-card>

        <el-card class="card-pub-notice card-ext-center-title" v-if="step===2">
            <div slot="header" class="notice_title">
                <span>相关资料上传</span>
            </div>
            <div class="commonNotice">
                <ex-card :headerName="item.datumName" v-for="item in datumImg.datumFiles" :key="item.id">
                    <datum-img ref="form7" :formData="item" class="commonNotice" @previewImage="previewImage"></datum-img>
                </ex-card>
            </div>
        </el-card>

        <el-row :gutter="24" class="submit-btn-group">
            <el-col :span="8" :offset="6">
                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-button @click="cancel">取消</el-button>
                    </el-col>
                    <el-col :span="6">
                        <el-button @click="next" v-if="step===1" type="primary">下一步</el-button>
                    </el-col>
                    <el-col :span="6" v-if="step===2">
                        <el-button @click="pre">上一步</el-button>
                    </el-col>
                    <el-col :span="6" v-if="step===2">
                        <el-button @click="save" type="primary">保存</el-button>
                    </el-col>
                    <el-col :span="6" v-if="step===2">
                        <el-button @click="submit" type="primary">保存并发起</el-button>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <sign ref="sign" v-if="showSign" @success="signCallback"></sign>
        <el-dialog :visible.sync="imgDlgVisible">
            <img width="100%" :src="imgDlgImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
import CustomerInfo from './customerInfo';
import BusinessContent from './businessContent';
import ElecBill from './elecBill';
import InvoiceInfo from './invoiceInfo';
import DeclarationNote from './declarationNote';
import EquipmentInfo from './equipmentInfo';
import Sign from '@/components/Sign/index';
import DatumImg from './datumImg';
import StepBar from '@/components/StepBar/index';
export default {
    name: 'BusinessApplyForm',
    components: {
        CustomerInfo,
        BusinessContent,
        ElecBill,
        InvoiceInfo,
        DeclarationNote,
        EquipmentInfo,
        Sign,
        DatumImg,
        StepBar,
    },
    props: {
        id: {
            type: [String, Number],
            default: ''
        }
    },
    computed: {
        total1() {
            return `${this.useTotalCapacity}`;
        },
        useTotalCapacity() {
            let useTotalCapacity = 0;
            for (const item of this.equipmentList) {
                useTotalCapacity += item.capacityCount;
            }
            return useTotalCapacity;
        }
    },
    watch: {
        loading: {
            handler(newVal) {
                if (newVal) {
                    this.loadingUI = this.$loading({
                        lock: true,
                        text: '提交中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                } else if (this.loadingUI) {
                    this.loadingUI.close();
                }
            },
            deep: true
        },
        businessContent: {
            handler(newVal) {
                if (newVal.totalCapacity) {
                    this.equipmentTotalCapacity = newVal.totalCapacity;
                }
            },
            deep: true
        }
    },
    data() {
        return {
            applySignatureForm: null,
            equipmentSignatureForm: null,
            loadingUI: null,
            loading: false,
            showSign: false,
            signImg1: '',
            signImg2: '',
            formState: 1,
            workflowId: '',
            bSignature: [],
            step: 1,
            imgDlgImageUrl: '',
            imgDlgVisible: false,
            customerInfo: {
                customerName: '',
                useLatitude: '',
                useLongitude: '',
                useAddr: '',
                contactAddr: '',
                zipCode: '',
                faxes: '',
                customerIdentity: '',
                customerType: '',
                customerIndustry: [],
                agent: '',
                agentNo: '',
                agentTel: '',
                idType: '',
                idNumber: '',
                useRelName: '',
                useRelTel: '',
                chargeRelName: '',
                chargeRelTel: '',
                billEmail: '',
                electronicBill: 0,
                sms: 0,
                smsTel: '',
                elecAssureType: ''
            },
            businessContent: {
                businessType: '',
                voltage: '',
                powerRequire: '',
                elecNature: '',
                tempDate: '',
                loadLevel: '',
                elecType: '',
                oldCapacity: '',
                updateCapacity: '',
                totalCapacity: '',
                tempStartDate: '',
                tempEndDate: ''
            },
            elecBill: {
                payType: '',
                payTypeOther: '',
                accountName: '',
                bankName: '',
                invoiceAccount: ''
            },
            invoiceInfo: {
                invoiceType: '',
                taxpayerName: '',
                taxNumber: '',
                account: '',
                bank: ''
            },
            declarationNote: {
                declarationNote: '',
            },
            equipmentInfo: {
                equipmentName: '',
                equipmentType: '',
                nominalVoltage: '',
                singleCapacity: '',
                num: '',
                capacityCount: 0,
                note: ''
            },
            datumImg: {
                datumFiles: [],
                businessDatumForms: []
            },
            equipmentList: [],
            // 折叠卡片包
            planFold: {
                customerInfo: false,
                businessContent: false,
                elecBill: false,
                invoiceInfo: false,
                declarationNote: false,
                customerNote: false
            },
            equipmentTotalCapacity: 0
        };
    },
    methods: {
        async bindKeyValues() {

        },
        async initFormValue() {
            await this.bindKeyValues();
        },
        // card 折叠方法
        shrinkComponents(name) {
            try {
                this.planFold[name] = !this.planFold[name];
            } catch (e) {
                console.log('shrinkComponents error');
            }
        },
        openSignDlg(type) {
            this.showSign = true;
            this.$nextTick(() => {
                this.$refs.sign.init(type);
            });
        },
        addEquipment() {
            this.equipmentList.push({
                ...this.equipmentInfo
            });
        },
        removeEquipment(index) {
            this.equipmentList.splice(index, 1);
        },
        signCallback(data, type) {
            const item = {
                file: data
            };
            if (type === 1) {
                item.signatureName = '客户签名';
                this.signImg1 = `${this.$client.serverUrl}files/${data.id}?token=${this.$store.state.token}`;
                this.applySignatureForm = item;
            } else {
                item.signatureName = '客户设备容量签名';
                this.signImg2 = `${this.$client.serverUrl}files/${data.id}?token=${this.$store.state.token}`;
                this.equipmentSignatureForm = item;
            }
            this.bSignature = this.bSignature.filter(e => {
                return e.signatureName !== item.signatureName;
            });
            this.bSignature.push(item);
        },
        async handleBack() {
            if (this.$route.params.routeMode) {
                let path = this.$route.path;
                this.$root.$emit('fe-framework:tab:close', path);
            } else {
                this.$emit('closePanel', 2);
            }
        },
        cancel() {
            this.$confirm('确认取消？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.handleBack();
            });
        },
        save() {
            this.formState = 0;
            this.submit();
        },
        validate(form) {
            return new Promise((resolve) => {
                if (!form) {
                    resolve(true);
                    return;
                }
                form.validate(valid => {
                    if (valid) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            });
        },
        pre() {
            this.step = 1;
        },
        async next() {
            let forms = [
                this.$refs.form1,
                this.$refs.form2,
                this.$refs.form3,
                this.$refs.form4,
                this.$refs.form5
            ];
            if (this.$refs.form6) {
                for (let item of this.$refs.form6) {
                    forms.push(item);
                }
            }

            for (let form of forms) {
                let valid = await this.validate(form.$refs.form);
                if (!valid) {
                    this.$message({
                        message: '请检查输入数据',
                        type: 'error'
                    });
                    return;
                }
            }

            /*if (!this.applySignatureForm) {
                this.$message({
                    message: '请检查客户电子签名',
                    type: 'error'
                });
                return;
            }*/

            if (this.equipmentList.length === 0) {
                this.$message({
                    message: '请添加用户设备',
                    type: 'error'
                });
                return;
            }
            /* if (!this.equipmentSignatureForm) {
                this.$message({
                    message: '请检查用户设备电子签名',
                    type: 'error'
                });
                return;
            }*/

            this.loading = true;
            this.$client.getBusinessImg({
                industrys: this.customerInfo.customerIndustry.join(','),
                totalCapacity: this.businessContent.totalCapacity,
                customerType: this.customerInfo.customerType
            }).then((data) => {
                if (data && data.code === 0) {
                    this.step = 2;
                    this.datumImg.datumFiles = data.data.map(e => {
                        e.files = [];
                        let datums = this.datumImg.businessDatumForms.filter(i => i.datumModelId === e.id);
                        if (datums.length > 0) {
                            e.files = datums[0].files;
                        }
                        return e;
                    });
                }
                this.loading = false;
            });
        },
        async submit() {
            let confirm = await this.$confirm('确定保存', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            if (confirm !== 'confirm') {
                return;
            }
            this.loading = true;
            this.$client.addBusinessApply({
                ...this.invoiceInfo,
                ...this.elecBill,
                ...this.customerInfo,
                applySignatureForm: this.applySignatureForm,
                equipmentSignatureForm: this.equipmentSignatureForm,
                businessDatumForms: this.datumImg.datumFiles.map(e => {
                    return {
                        datumModelId: e.id,
                        datumName: e.datumName,
                        files: e.files,
                        ossIds: []
                    };
                }),
                //businessNumber: '',//后台自动生成
                ...this.businessContent,
                //customerNumber: '',//后台自动生成
                elecUnit: '',
                equipmentCapacityForms: [...this.equipmentList],
                equipmentTotalCapacity: this.equipmentTotalCapacity,
                formState: this.formState,
                id: this.id,
                note: this.declarationNote.declarationNote,
                useTotalCapacity: this.useTotalCapacity
            }).then((data) => {
                this.$message({
                    message: '申请成功',
                    type: 'success'
                });
                this.handleBack();
                this.loading = false;
            });
        },
        loadData() {
            if (!this.id) {
                return;
            }
            this.$client.getBusinessInfo(this.id).then((data) => {
                // 不能全取所有字段，因此仅拷贝需要字段
                this.customerInfo = {
                    cid: data.data.cid, //cid修改时必须提交
                    customerName: data.data.customerName,
                    useLatitude: data.data.useLatitude,
                    useLongitude: data.data.useLongitude,
                    useAddr: data.data.useAddr,
                    contactAddr: data.data.contactAddr,
                    zipCode: data.data.zipCode,
                    faxes: data.data.faxes,
                    customerIdentity: data.data.customerIdentity,
                    customerType: data.data.customerType,
                    customerIndustry: data.data.customerIndustry.map(e => parseInt(e)),
                    agent: data.data.agent,
                    agentNo: data.data.agentNo,
                    agentTel: data.data.agentTel,
                    idType: data.data.idType,
                    idNumber: data.data.idNumber,
                    useRelName: data.data.useRelName,
                    useRelTel: data.data.useRelTel,
                    chargeRelName: data.data.chargeRelName,
                    chargeRelTel: data.data.chargeRelTel,
                    billEmail: data.data.billEmail,
                    electronicBill: data.data.electronicBill,
                    sms: data.data.sms,
                    smsTel: data.data.smsTel,
                    elecAssureType: data.data.elecAssureType
                };

                this.businessContent = {
                    businessType: data.data.businessType,
                    voltage: data.data.voltage,
                    powerRequire: data.data.powerRequire,
                    elecNature: data.data.elecNature,
                    tempStartDate: data.data.tempStartDate,
                    tempEndDate: data.data.tempEndDate,
                    tempDate: data.data.tempStartDate && data.data.tempEndDate ? [data.data.tempStartDate, data.data.tempEndDate] : [],
                    loadLevel: data.data.loadLevel,
                    elecType: data.data.elecType,
                    oldCapacity: data.data.oldCapacity,
                    updateCapacity: data.data.updateCapacity,
                    totalCapacity: data.data.totalCapacity,
                };
                this.elecBill = {
                    payType: data.data.payType,
                    payTypeOther: data.data.payTypeOther,
                    accountName: data.data.accountName,
                    bankName: data.data.bankName,
                    invoiceAccount: data.data.invoiceAccount,
                };

                this.invoiceInfo = {
                    invoiceType: data.data.invoiceType,
                    taxpayerName: data.data.taxpayerName,
                    taxNumber: data.data.taxNumber,
                    account: data.data.account,
                    bank: data.data.bank,
                };

                this.declarationNote = {
                    declarationNote: data.data.note,
                };

                this.equipmentList = data.data.equipmentCapacityForms.map(e => {
                    return {
                        equipmentName: e.equipmentName,
                        equipmentType: e.equipmentType,
                        nominalVoltage: e.nominalVoltage,
                        singleCapacity: e.singleCapacity,
                        num: e.num,
                        capacityCount: e.capacityCount,
                        note: e.note
                    };
                });

                this.bSignature = [];
                this.bSignature.push(data.data.applySignatureForm);
                this.bSignature.push(data.data.equipmentSignatureForm);
                this.applySignatureForm = data.data.applySignatureForm;
                this.equipmentSignatureForm = data.data.equipmentSignatureForm;
                this.signImg1 = `${this.$client.serverUrl}files/${data.data.applySignatureForm.file.id}?token=${this.$store.state.token}`;
                this.signImg2 = `${this.$client.serverUrl}files/${data.data.equipmentSignatureForm.file.id}?token=${this.$store.state.token}`;

                this.equipmentTotalCapacity = data.data.equipmentTotalCapacity;

                this.datumImg.businessDatumForms = data.data.businessDatumForms;
            });
        },
        previewImage(imgUrl) {
            this.imgDlgImageUrl = imgUrl;
            this.imgDlgVisible = true;
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        }
    },
    created() {
        this.initFormValue();
        this.loadData();
    }
};
</script>

<style lang="less" scoped>
</style>
