<template>
    <div>
        <el-upload
            :headers="uploadHeaders"
            :action="uploadUrl"
            multiple
            accept="image/*"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :before-upload="beforeUpload"
            :on-success="uploadSuccess"
            :on-remove="removeFile"
            :file-list="fileList"
            :auto-upload="true"
        >
            <i class="el-icon-plus"></i>
        </el-upload>

        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'image-uploader',
    props: {},
    data() {
        return {
            fileList: [],
            acceptImg: ['png', 'jpg', 'jpeg'],
            uploadHeaders: {
                token: this.$store.state.token
            },
            uploadUrl: this.axios.defaults.baseURL + '/files',
            dialogImageUrl: '',
            dialogVisible: false
        };
    },
    methods: {
        removeFile(file, fileList) {
            this.init(fileList);
        },
        uploadSuccess(res, file, fileList) {
            this.init(fileList);
        },
        init(fileList) {
            //统一数据格式化
            this.fileList = fileList.map(item => {
                return item.response
                    ? {...item.response.data, url: item.url}
                    : {...item, url: this.uploadUrl + '/' + item.id + '?token=' + this.uploadHeaders.token};
            });
        },
        beforeUpload(file) {
            let types = file.type.split('/');
            const isPDF = this.acceptImg.includes(types[1]);
            const isLt2M = file.size / 1024 / 1024 < 10;

            if (!isPDF) {
                this.$message.error('只能上传图片!');
                return false;
            }
            if (!isLt2M) {
                this.$message.error('图片大小不能超过10MB!');
                return false;
            }
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        getFileList() {
            return this.fileList;
        }
    }
};
</script>

<style scoped></style>
