<template>
    <div>
        <step-bar :active="3" @clickStep="clickStep"></step-bar>
        <el-card class="card-pub-notice card-ext-center-title">
            <div class="commonNotice">
                <ex-card headerName="验收申请文件">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24">
                            <el-col :span="8">
                                <div class="uploader">
                                    <ex-uploader v-model="completionApplyFiles" :singleFileMode='true' :disabled="true"></ex-uploader>
                                    <br/>
                                    <span>竣工检验申请书</span>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="uploader">
                                    <ex-uploader v-model="completionAskCheckFiles" :singleFileMode='true' :disabled="true"></ex-uploader>
                                    <br/>
                                    <span>竣工报验单</span>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="uploader">
                                    <ex-uploader v-model="completionCheckFiles" :singleFileMode='true' :disabled="true"></ex-uploader>
                                    <br/>
                                    <span>竣工资料审核单</span>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
                <ex-card headerName="现场作业表">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <div class="uploader">
                                    <ex-uploader v-model="completionCheckSceneFiles" :singleFileMode='true' :disabled="true"></ex-uploader>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
                <ex-card headerName="客户受电工程竣工检验意见书">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <ex-card headerName="基本信息">
                            <div class="commonNotice">
                            <div class="ulbox">
                                <el-row :gutter="24" v-for="(item,index) in customerInfo" :key="index">
                                    <el-col :span="6" style="text-align:right;">
                                        <span>{{item.name}}:</span>
                                    </el-col>
                                    <el-col :span="18" style="text-align:left;">
                                        <span>{{item.value}}</span>
                                    </el-col>
                                </el-row>
                            </div>
                            </div>
                        </ex-card>
                        <ex-card headerName="客户承诺">
                            <div class="commonNotice">
                            <div class="ulbox">
                                <div>本户受电工程委托有承装（修）电力设施许可证单位安装，</div>
                                <div>
                                        <el-checkbox-group v-model="constructionItem">
                                            <el-checkbox label="1" :disabled="true">线路</el-checkbox>
                                            <el-checkbox label="2" :disabled="true">变配电工程</el-checkbox>
                                        </el-checkbox-group>
                                </div>
                            </div>
                            </div>
                        </ex-card>
                        <ex-card headerName="竣工检验项目是否符合标准">
                            <div class="commonNotice">
                            <div class="ulbox">
                                <div v-for="(item, index) in checkItem" :key="index">
                                    <el-checkbox v-model="item.isRequired" :true-label="1" :false-label="0" :label="item.itemName" :disabled="true"></el-checkbox>
                                </div>
                            </div>
                            </div>
                        </ex-card>
                        <ex-card v-for="(item,index) in signatureForms" :key="index" :headerName="item.signatureName">
                            <div class="commonNotice">
                            <div class="ulbox">
                                <p>{{item.signatureAdvice}}</p>
                            </div>
                            </div>
                        </ex-card>
                    </div>
                    </div>
                </ex-card>
                <ex-card headerName="客户受电工程竣工检验意见书 （背面）">
                            <div class="commonNotice">
                            <div class="ulbox">
                                <p>{{rectifyOpinionssignatureAdvice}}</p>
                            </div>
                            </div>
                        </ex-card>
            </div>
        </el-card>

        <el-row v-if="showBack" :gutter="24" class="submit-btn-group">
        <el-col :span="24">
            <el-button @click="back" style="position: absolute;left:0; top:0; right:0; bottom: 0; margin: auto;height:26px;">返回</el-button>
        </el-col>
        </el-row>

    </div>
</template>

<script>
import axios from '@/client/axios';
import StepBar from '@/components/StepBar/index';
export default {
    name: 'CompletionAcceptanceShow',
    components: {
        StepBar
    },
    props: {
        id: {
            type: [String, Number],
            default: '0'
        },
        showBack: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            completionApplyFiles: [],
            completionAskCheckFiles: [],
            completionCheckFiles: [],
            completionCheckSceneFiles: [],
            customerInfo: [],
            constructionItem: [],
            checkItem: [],
            signatureForms: [],
            rectifyOpinionssignatureAdvice: ''
        };
    },
    methods: {
        loadData() {
            this.$client.getCompletionAcceptance(this.id).then(data => {
                // 验收申请文件
                for (let i = 0; i < data.data.completionFiles.length; i++) {
                    let item = data.data.completionFiles[i];
                    if (item.remark === '竣工检验申请书') {
                        this.completionApplyFiles = item.files;
                    } else if (item.remark === '竣工报验单') {
                        this.completionAskCheckFiles = item.files;
                    } else if (item.remark === '竣工资料审核单') {
                        this.completionCheckFiles = item.files;
                    }
                }

                // 现场作业表
                this.completionCheckSceneFiles = data.data.completionCheckSceneFiles;

                // 客户受电工程竣工检验意见书
                // 基本信息
                this.customerInfo.push({name: '客户编号', value: data.data.customerNumber});
                this.customerInfo.push({name: '工单号', value: data.data.businessNumber});
                this.customerInfo.push({name: '用电地址', value: data.data.useAddr});
                this.customerInfo.push({name: '报装容量', value: data.data.totalCapacity});
                this.customerInfo.push({name: '客户联系人', value: data.data.useRelName});
                this.customerInfo.push({name: '联系电话', value: data.data.useRelTel});

                // 客户承诺
                this.constructionItem = data.data.constructionItem;

                // 竣工检验项目是否符合标准
                this.checkItem = data.data.completionAcceptanceItems;

                // 意见
                this.signatureForms = [];
                this.signatureForms.push({
                    ...data.data.acceptanceSignature,
                    signatureName: '供电企业竣工检验意见',
                });
               /* this.signatureForms.push({
                    ...data.data.agentSignature,
                    signatureName: '客户确认检验意见',
                });
                this.signatureForms.push({
                    ...data.data.workUnitSignature,
                    signatureName: '施工单位确认检验意见',
                });*/
                /*this.signatureForms.push({
                    ...data.data.customerSignature,
                    signatureName: '客户签名',
                });*/

                // 客户受电工程竣工检验意见书 （背面）
                this.rectifyOpinionssignatureAdvice = data.data.rectifyOpinions;
            });
        },
        back() {
            this.$emit('closePanel');
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        }
    },
    async created() {
        await this.loadData();
    }
};
</script>

<style lang="less" scoped>
.card-ext-center-title {
    .commonNotice {
        line-height: 2.1;
    }
}
</style>
