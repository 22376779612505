<template>
    <el-form :model="formData" :rules="formRules" ref="form" size="mini">
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item prop="electricSubstationAddr" :label="$t('electricPlan.form.electricSubstationAddr')">
                        <el-radio v-model="formData.electricSubstationAddr" v-for="item in keyValues.electricSubstationAddr" :label="parseInt(item.itemKey)" :key="parseInt(item.itemKey)">{{item.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="electricAccessWay" :label="$t('electricPlan.form.electricAccessWay')">
                        <el-radio v-model="formData.electricAccessWay" v-for="item in keyValues.electricAccessWay" :label="parseInt(item.itemKey)" :key="parseInt(item.itemKey)">{{item.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item prop="deviceCapacityInfo" label="设备容量详细情况">
                        <span>{{deviceCapacityInfo}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24" v-for="(item,index) in formData.highVoltageMotors" :key="index">
                <el-col :span="12">
                    <el-form-item :prop="'highVoltageMotors.'+index+'.transformerCapacity'" label="高压电机容量(kVA)"
                                  :rules="{required: true, message: '高压电机容量不能为空', trigger: 'blur'}">
                        <el-input v-model="item.transformerCapacity"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item :prop="'highVoltageMotors.'+index+'.num'" label="高压电机台数"
                                  :rules="{required: true, message: '高压电机台数不能为空', trigger: 'blur'}">
                        <el-input-number v-model="item.num" style="width:100%" :min="1" controls-position="right"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="1" class="ui-layout-flex ui-layout-flex--items_center" style="height: 80px;">
                    <el-link><i v-if="index===0" class="el-icon-close subheader_close" @click="removeHighVoltageMotor(index)"></i></el-link>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-button icon="el-icon-plus" @click="addHighVoltageMotor">新增高压电机</el-button>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="4" style="text-align:right;">
                    <span>合计总容量为:</span>
                </el-col>
                <el-col :span="20" style="text-align:left;">
                    <span>{{totalHighVoltageMotor}}</span>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="4" style="text-align:right;">
                    <span>报装容量:</span>
                </el-col>
                <el-col :span="20" style="text-align:left;">
                    <span>原装:{{formData.customer.oldCapacity}};新装:{{formData.customer.updateCapacity}}(kVA);总计:{{formData.customer.totalCapacity}}(kVA)</span>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="4" style="text-align:right;">
                    <span>用电性质:</span>
                </el-col>
                <el-col :span="20" style="text-align:left;">
                    <span>{{formData.customer.elecNature}}</span>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="4" style="text-align:right;">
                    <span>供电电压:</span>
                </el-col>
                <el-col :span="20" style="text-align:left;">
                    <span>{{formData.voltage}}</span>
                </el-col>
            </el-row>
            <el-row :gutter="24" v-for="(item,index) in formData.electricPlanMeteringEntities" :key="item.reconnaissancePlanId">
                <el-col :span="4" style="text-align:right;">
                    <span>电源点{{index+1}}:</span>
                </el-col>
                <el-col :span="20" style="text-align:left;">
                    <span>{{item.powerAp}}</span>
                </el-col>
                <el-col :span="8" :offset="4">
                    <el-form-item :prop="'electricPlanMeteringEntities.'+index+'.isInvestmentInterface'"
                                  label="供电源(主/备)"
                                  :rules="{required: true, message: '供电源(主/备)不能为空', trigger: 'blur'}">
                        <el-radio v-model="item.isInvestmentInterface" :label="1">主</el-radio>
                        <el-radio v-model="item.isInvestmentInterface" :label="2">备</el-radio>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :prop="'electricPlanMeteringEntities.'+index+'.supplyCapacity'"
                                  :rules="{required: true, message: '供电容量不能为空', trigger: 'blur'}"
                                  label="供电容量">
                        <el-input v-model="item.supplyCapacity"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
    </el-form>
</template>
<script>
export default {
    name: 'PowerCapacityInfo',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    electricSubstationAddr: '',
                    electricAccessWay: '',
                    transformerCapacitys: [],
                    highVoltageMotors: [],
                    customer: {},
                    electricPlanMeteringEntities: [],
                    voltage: ''
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                electricSubstationAddr: [
                    {
                        required: true,
                        message: '变电所所址型式 ' + '必填',
                        trigger: 'change'
                    }
                ],
                electricAccessWay: [
                    {
                        required: true,
                        message: '高压侧电气主接线方式 ' + '必填',
                        trigger: 'change'
                    }
                ]
            },
            keyValues: {
                electricSubstationAddr: [],
                electricAccessWay: [],
                elecNature: []
            },
            highVoltageMotor: {
                transformerCapacity: '',
                num: ''
            }
        };
    },
    computed: {
        deviceCapacityInfo() {
            return this.formData.transformerCapacitys.map(e => {
                return e.transformerCapacity + '变压器' + e.num + '台';
            }).join(';');
        },
        totalHighVoltageMotor() {
            let reg = /(\d+)/;
            let result = 0;
            for (let i = 0; i < this.formData.highVoltageMotors.length; i++) {
                let item = this.formData.highVoltageMotors[i];
                if (item.transformerCapacity === '' || item.num === '') {
                    continue;
                }
                let capMatch = reg.exec(item.transformerCapacity);
                let numMatch = reg.exec(item.num);
                if (!capMatch || !numMatch) {
                    continue;
                }
                result += capMatch[0] * numMatch[0];
            }
            this.formData.transformerCapacitys.forEach(item => {
                result += Number(item.transformerCapacity) * Number(item.num);
            });
            return result + '(kW)';
        }
    },
    methods: {
        async bindKeyValues() {
            // const data = await Promise.all([
            //     this.$client.getDicData('electric_substation_addr'),
            //     this.$client.getDicData('electric_access_way'),
            //     this.$client.getDicData('elec_nature'),
            // ]);
            // this.keyValues.electricSubstationAddr = data[0].data || [];
            // this.keyValues.electricAccessWay = data[1].data || [];
            // this.keyValues.elecNature = data[2].data || [];

            const dictMap = [
                {parentCode: 'electric_substation_addr', listName: 'electricSubstationAddr'},
                {parentCode: 'electric_access_way', listName: 'electricAccessWay'},
                {parentCode: 'elec_nature', listName: 'elecNature'},
            ];
            this.$client.getDicDataArr(dictMap.map((it) => it.parentCode)).then((res) => {
                let {data: resData} = res;
                for (let key in resData) {
                    let data = resData[key];
                    let dict = dictMap.filter((it) => it.parentCode === key)[0];
                    this.keyValues[dict.listName] = data;
                }
            });
        },
        addHighVoltageMotor() {
            this.formData.highVoltageMotors.push({
                ...this.highVoltageMotor
            });
        },
        removeHighVoltageMotor(index) {
            this.formData.highVoltageMotors.splice(index, 1);
        }
    },
    created() {
        this.bindKeyValues();
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>


