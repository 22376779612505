<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item prop="isBusinessFee" label="">
                        <el-radio v-model="formData.isBusinessFee" v-for="item in keyValues.isBusinessFee" :label="item.itemKey" :key="item.itemKey">{{item.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24" v-if="formData.isBusinessFee===1">
                <el-col :span="12">
                    <el-form-item prop="businessFee" label="每kVA/kW(元)">
                        <el-input v-model="formData.businessFee"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="businessTotalFee" label="合计(元)">
                        <el-input v-model="formData.businessTotalFee"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'BusinessFeeInfo',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    isBusinessFee: '',
                    businessFee: '',
                    businessTotalFee: ''
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                isBusinessFee: [
                    {
                        required: true,
                        message: '业扩费用 ' + '必填',
                        trigger: 'change'
                    }
                ],
                businessFee: [
                    {
                        required: true,
                        message: '每kVA/kW(元) ' + '必填',
                        trigger: 'change'
                    }
                ],
                businessTotalFee: [
                    {
                        required: true,
                        message: '合计(元) ' + '必填',
                        trigger: 'change'
                    }
                ],
            },
            keyValues: {
                isBusinessFee: [
                    {itemKey: 1, itemValue: '高可靠性供电费'},
                    {itemKey: 0, itemValue: '无'},
                ],
            }
        };
    },
    methods: {
        async bindKeyValues() {
            const data = await Promise.all([
                this.$client.getDicData('metering_type'),
            ]);
            this.keyValues.meteringType = data[0].data || [];
        },
    },
    created() {
        this.bindKeyValues();
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>


