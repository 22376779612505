<template>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item prop="invoiceType" :label="'发票类型'">
                        <el-radio v-model="formData.invoiceType" v-for="item in keyValues.invoiceType" :label="parseInt(item.itemKey)" :key="parseInt(item.itemKey)">{{item.itemValue}}</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="6">
                    <el-form-item :label="'纳税人名称'" prop="taxpayerName">
                        <el-input v-model="formData.taxpayerName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item :label="'税号'" prop="taxNumber">
                        <el-input v-model="formData.taxNumber"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item :label="'账号'" prop="account">
                        <el-input v-model="formData.account"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item :label="'开户银行'" prop="bank">
                        <el-input v-model="formData.bank"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        </el-form>
</template>
<script>
export default {
    name: 'InvoiceInfo',
    components: {
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    invoiceType: '',
                    taxpayerName: '',
                    taxNumber: '',
                    account: '',
                    bank: ''
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                invoiceType: [
                    {
                        required: false,
                        message: '发票类型' + ' ' + '必填',
                        trigger: 'change'
                    }
                ],
            },
            keyValues: {
                invoiceType: [],
            }
        };
    },
    computed: {},
    methods: {
        async bindKeyValues() {
            const data = await Promise.all([
                this.$client.getDicData('invoice_type'),
            ]);
            this.keyValues.invoiceType = data[0].data || [];
        },
        async initFormValue() {
            await this.bindKeyValues();
        },
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>
